import React from 'react';
import "./StatisticInfoLine.css";
import { CircularProgressbarWithChildren  } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { CSVLink, CSVDownload } from "react-csv";

export default class StatisticsBlock extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidUpdate() {


    }

    renderCircle = (name, value, label, icon) => {

        return <CircularProgressbarWithChildren
            key={label+value+name}
            value={value}
            maxValue={1}
            styles={{
                margin: "5px",
                padding: "5px",

                path: {
                // Path color
                stroke: `#fff`,
                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                strokeLinecap: 'butt',
                // Customize transition animation
                transition: 'stroke-dashoffset 0.5s ease 0s',
                // Rotate the path
            //    transform: 'rotate(0.25turn)',
                transformOrigin: 'center center',
            },
                // Customize the circle behind the path, i.e. the "total progress"
                trail: {
                // Trail color
                stroke: '#4dbdd6',
                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                strokeLinecap: 'butt',
                // Rotate the trail
                transform: 'rotate(0.25turn)',
                transformOrigin: 'center center',
            }

            }}
        >
            {icon ?
                <img
                    className="completed-icon"
                    src={icon}
                /> : null}
                <span className="completed-title">{name}</span>
                <span className="completed-persents">{label}</span>


        </CircularProgressbarWithChildren >



    };

    renderGenderLine = (img, value)=>{

        let lineWidth = "" + value * 100 + "%";
      //  console.log(lineWidth);
        return  <div className="first-gender-line">
            <div className="first-gender-line-image-place">
                <img
                    className="first-gender-line-image"
                    src={img}
                />
            </div>
            <div className="first-gender-line-statistic">
                <div className="first-gender-line-statistic-small" style={{width: lineWidth}}/>
            </div>
        </div>
    };

    render() {

        let {data} = this.props;

        let totalPeoples = data.gender.male + data.gender.female +data.gender.other + data.gender.unknown;
        let totalAges =0;
        data.age.forEach(a=> totalAges = totalAges +a);

        let maxQuestionCount =0;
        data.logs.forEach(l=> {
            if (l.answers.length >  maxQuestionCount) maxQuestionCount = l.answers.length;
        });
        let questionCaptions = [];
        for (let i=0;i<maxQuestionCount; i++)
            questionCaptions.push("question " + (i+1));

        let csvData = [];
        csvData.push(["date","platform","gender","age","experienceName", "experienceId", "quizId", ...questionCaptions]);

        data.logs.forEach(l=> {

            let line = [];
            line.push(l.date);
            line.push(l.platform);
            line.push(l.gender);
            line.push(l.age);
            line.push(l.experienceName);
            line.push(l.experienceId);
            line.push(l.quizId);
            for (let i = 0; i < maxQuestionCount; i++)
                if (l.answers[i] !== undefined) {
                if (l.answers[i].answerId !== undefined)
                    line.push(l.answers[i].answerId);
                else
                    line.push(decodeURI(l.answers[i].answerText));
                }
            else
                    line.push("");

            csvData.push(line);
        });

        return (<section className="Statistic-info-line-section">
            <div className="all-users-block">
                <div className="all-users-title-block">
                    <span className="all-users-title">{data.name}</span>
                </div>
                {data.progress.started ?

                    <div className="all-users-content">
                        <div className="p-3">
                        {   this.renderCircle(
                            "Completed",
                            data.progress.finished / data.progress.started,
                            Math.round(data.progress.finished / data.progress.started * 100)+ "%",
                            "/img/statistics-completed-icon.png"
                        ) }</div><div className="p-3">
                        {   this.renderCircle(
                            "In Progress",
                            1 - data.progress.finished / data.progress.started,
                            Math.round((1 - data.progress.finished / data.progress.started) * 100) + "%",
                            "/img/statistics-in-progress-icon.png"
                        ) }</div>

                    </div>

                    : <span className="FormText CenterContent d-flex ml-3" >No data</span>}

            </div>
            {data.progress.started ?
            <div className="gender">
                <div className="gender-title-block">
                    <div className="gender-title">Gender</div>
                </div>

                <div className="gender-content">
                    {this.renderGenderLine("/img/statistics-female-icon.png", data.gender.female / totalPeoples )}
                    {this.renderGenderLine("/img/tie.svg", data.gender.male / totalPeoples )}
                    {this.renderGenderLine("/img/other.png", data.gender.other / totalPeoples )}
                    {this.renderGenderLine("/img/unknown.png", data.gender.unknown / totalPeoples )}
                </div>

            </div>
                : null}
            {data.progress.started ?
            <div className="all-users-block">
                <div className="all-users-title-block">
                    <span className="all-users-title">Ages</span>
                </div>
                <div className="all-users-content p-3">
                    {
                        data.age
                            .filter(age => age !== 0)
                            .map((age, index) => <div className="p-3"> {
                                this.renderCircle(
                                    index === 0 ? "Unknown" : (((index + 1) * 10 + 1) + "-" + (index + 2) * 10),
                                    age / totalAges,
                                    Math.round(age / totalAges * 100) + "%",
                                    null
                                )} </div>)
                    }

                </div>
            </div>
            : null}
            {data.progress.started ?
                <CSVLink data={csvData} className="download-data"
                         filename={new Date().toISOString().split('.')[0] + "-" + data.name + "-answers.csv"}>
                <img
                    className="download-data-image"
                    src="/img/statistics-download-data-icon.png"
                />
                <span className="download-data-title">Download Data</span>
                    </CSVLink>
            : null}
        </section>);

    }
}
import React from 'react';


export default class EditableSpan extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            focused: false,
            value: this.props.textValue
        };
    }

    componentDidUpdate(){

        if (this.state.focused)
            this.nameInput.focus();  //make cursor active on new element
    }

    render() {

        let {value, focused} = this.state;
        let {item, fieldName, langCode = "", updateHandler, textValue, isEditable = "true"} = this.props;

        let completeFieldName = fieldName + langCode;

        let fieldStyle = {width: "100%"};
        let rtlStyle = {direction: "rtl", textAlign: "right"};
        let emptyStyle = {color: "gray"};

        if (langCode === "he")
            fieldStyle = {...fieldStyle, ...rtlStyle};
        if (!value)
            fieldStyle = {...fieldStyle, ...emptyStyle};

        let renderElement = <span onClick={()=>{ this.setState({focused: true})}} style={fieldStyle} >{value ? value : "empty"}</span> ;  //default render as span

        if (focused === true)    //render as input field
            renderElement = <form style={fieldStyle}>
                <input type="text"

                       onChange={(e) => {
                           let val = e.target.value;

                           this.setState({value: val});

                           updateHandler(item, completeFieldName, val);
                           updateHandler(item, 'updated', 1);
                        //   console.log("changes:", item, completeFieldName, val);
                       }}

                       onKeyPress={(e => {
                           if (e.which === 13 || e.keyCode === 13 || e.keyCode === 27) {
                               e.preventDefault()
                               this.setState({focused: false});  //loose focus by Enter
                           }
                       })}

                       onBlur={()=>{this.setState({focused: false})}}
                       ref={(input) => { this.nameInput = input; }}

                       style={fieldStyle}
                       value={value ? value : ""} //show only local state value
                />
            </form>;

        return renderElement;
    }
}
import React, { useState } from "react";
import "./NavbarMenu.css";
import "./DropDown.css";

export const DropDown = ({ list, current, change }) => {
    const [isDropdownOpened, setIsDropdownOpened] = useState(false);

    return (
        <div className="dropdown">

            <button
                onClick={() => setIsDropdownOpened(!isDropdownOpened)}
                id="myDropdown"
                className="lang-list dropbtn"
            >
                <span className="lang-list-text">{current}</span>
                <div className="lang-triengl"/>
            </button>

            <div className={`dropdown-content ${isDropdownOpened ? "active" : ""}`}>
                {list.map((item) => (
                    <React.Fragment key={item.code}>
                        <button className="dropdown-link-place" onClick={()=> {
                            change(item.code);
                            setIsDropdownOpened(!isDropdownOpened);
                        } }>
                            <span className="dropdown-link" >
                                {item.code}
                            </span>
                        </button>
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {useHttp} from '../../hooks/http.hook'
import {AuthContext} from '../../context/AuthContext'
import { LanguagesContext } from "../../context/LanguagesContext";
import {Loader} from '../../components/Loader'
import {Toaster} from '../../components/Toaster'
import {Link} from 'react-router-dom'

import { DropdownButton, Dropdown, Button, Form, Container, Row, Col, ListGroup, Badge, Tabs, Tab, Modal} from 'react-bootstrap';
import { FaTrashAlt, FaSave, FaExternalLinkAlt, FaSortUp, FaSortDown } from 'react-icons/fa';
import EditableSpan from "../../components/EditableSpan";

import './ContentPage.css';

export const GroupDetailPage = () => {
    const {token, group, role} = useContext(AuthContext)
    const {pageLanguage, activeLanguage} = useContext(LanguagesContext);

    const [users, setUsers] = useState([])
    const [group_scenario, setGroupScenario] = useState([])
    const [passwords_list, setPasswordsList] = useState({items: [], id: null})
    const [dropdown_scenario, setDropdownScenario] = useState([])
    const {request, loading, error} = useHttp()
    const [receivedError, setReceivedError] = useState(error)


    const [passwordsListToShow, setPasswordsListToShow] = useState([]);

    let temp = useParams().id
    let groupId

    if (role === 'admin') {
        groupId = temp
    } else if (role === 'moderator') {
        groupId = group
    }

    /* const [activeLanguage, setActiveLanguage] = useState([])
     const fetchActiveLanguage = useCallback(async () => {
       try {
         const fetched = await request('/settings/language/active', 'GET', null, {
           'x-access-token': token
         })

         if (fetched.languages) {
           setActiveLanguage(fetched.languages)
         }
         // console.log(settingsLanguage);

       } catch (e) {setReceivedError(e)}
     }, [token, request])

     useEffect(() => {
       fetchActiveLanguage()
     }, [fetchActiveLanguage])
     */

    const [groupName, setGroupName] = useState()
    const [groupLangCode, setGroupLangCode] = useState()
    const fetchGroup = useCallback(async () => {
        try {
            let path
            if (role === 'admin') {
                path = `/groups/${groupId}`
            } else if (role === 'moderator') {
                path = '/moderator/group_data'
            }

            const fetched = await request(path, 'GET', null, {
                'x-access-token': token
            })

            if (fetched.group) {
                console.log('set name', fetched.group);
                setGroupName(fetched.group.name)
                setGroupLangCode(fetched.group.lang_code)
            }
        } catch (e) {
            setReceivedError(e)
        }
    }, [token, request, groupId])

    useEffect(() => {
        fetchGroup()
    }, [fetchGroup])

    const [userForm, setUserForm] = useState({group_id: groupId})
    const [userUpdateForm, setUserUpdateForm] = useState([])

    const changeUserForm = event => {
        setUserForm({...userForm, [event.target.name]: event.target.value})
        console.log('new user form', userForm);
    }

    const updateUserHandler = (data, key, val) => {
        const temp = userUpdateForm
        if (temp.some(item => item.id === data.id)) {
            console.log("Object found inside the array.");
            setUserUpdateForm(
                userUpdateForm => userUpdateForm.map(
                    item => {
                        if (item.id === data.id) {
                            item[key] = val
                        }
                        return item
                    }
                )
            )
        } else {
            console.log("Object not found.");
            setUserUpdateForm([...userUpdateForm, data])
        }
    }

    const fetchUsers = useCallback(async () => {
        let path
        if (role === 'admin') {
            path = `/user/group/${groupId}`
        } else if (role === 'moderator') {
            path = '/moderator/group'
        }

        try {
            const fetched = await request(path, 'GET', null, {
                'x-access-token': token
            })
            if (fetched.users) {
                console.log(fetched.users);
                setUsers(fetched.users)
            }

        } catch (e) {
            setReceivedError(e)
        }
    }, [token, request, groupId, role])

    const fetchScenarios = useCallback(async () => {

        let path
        if (role === 'admin') {
            path = `/scenario/${groupId}`
        } else if (role === 'moderator') {
            path = `/moderator/scenario`
        }
        try {
            const fetched = await request(path, 'GET', null, {
                'x-access-token': token
            })

            if (role === 'admin' && fetched.dropdown_scenario && fetched.dropdown_scenario.length) {
                console.log(fetched.dropdown_scenario);
                setDropdownScenario(fetched.dropdown_scenario)
            }

            if (fetched.group_scenario && fetched.group_scenario.length) {
                console.log(fetched.group_scenario);
                setGroupScenario(fetched.group_scenario)
            }

        } catch (e) {
            setReceivedError(e)
        }
    }, [token, request, groupId, role])

    useEffect(() => {
        fetchUsers()
    }, [fetchUsers])

    useEffect(() => {
        fetchScenarios()
    }, [fetchScenarios])


    const deleteUser = async (val) => {
        try {
            const data = await request(`/user/remove/${val}`, 'POST', null, {
                'x-access-token': token
            })
            console.log('error ', error);
            if (data.changed === 1) {
                setUsers(users => users.filter(item => item.id !== val))
            }

        } catch (e) {
            setReceivedError(e)
        }

    }

    const changeUser = async (user_id) => {
        let data_form = userUpdateForm.filter(item => {
            return item.id === user_id
        })
        try {
            const data = await request(`/user/update/${user_id}`, 'POST', data_form[0], {
                'x-access-token': token
            })
            console.log('error ', error);
            if (data.changed === 1) {
                setUsers(
                    users => users.map(
                        item => {
                            if (item.id === user_id) {
                                item.name = data_form[0].name
                                item.email = data_form[0].email
                                item.role = data_form[0].role
                            }
                            return item;
                        }
                    )
                )
            }
            setUserUpdateForm(user => user.filter(item => item.id !== user_id))
        } catch (e) {
            setReceivedError(e)
        }

    }

    const addUser = async () => {
        try {
            const data = await request('/user/add', 'POST', userForm, {
                'x-access-token': token
            })
            console.log('error ', error);
            if (data.user) {
                setUsers(users => [...users, data.user[0]])
            }

        } catch (e) {
            setReceivedError(e)
        }
    }

    const userActivationChange = async (active, id) => {
        console.log('user active', active);
        console.log('user active opposite', !active);
        const new_activation = !active
        try {
            const data = await request(`/user/update_activation/${id}`, 'POST', {active: new_activation}, {
                'x-access-token': token
            })
            console.log('error ', error);
            if (data.changed === 1) {
                setUsers(
                    users => users.map(
                        item => {
                            if (item.id === id)
                                item.active = !active
                            return item;
                        }
                    )
                )
            }
        } catch (e) {
            setReceivedError(e)
        }

    }

    const addScenarioToGroup = async (scenario) => {
        try {
            const data = await request(`/scenario/group_add/${scenario.id}/${groupId}`, 'GET', null, {
                'x-access-token': token
            })
            console.log('error ', error);
            if (data.changed === 1) {
                scenario.scenario_login = data.scenario[0].scenario_login
                scenario.scenario_credentials_id = data.scenario[0].credentials_id
                scenario.scenario_password = data.scenario[0].scenario_password
                // setUsers(users => [...users, data.user[0]] )
                setGroupScenario(group_scenario => [...group_scenario, scenario])
                setDropdownScenario(dropdown_scenario => dropdown_scenario.filter(item => item.id !== scenario.id))
            }


        } catch (e) {
            setReceivedError(e)
        }
    }

    const deleteScenarioFromGroup = async (scenario) => {
        try {
            const data = await request(`/scenario/group_remove/${scenario.id}/${groupId}`, 'GET', null, {
                'x-access-token': token
            })
            console.log('error ', error);
            if (data.changed === 1) {
                // setGroupScenario(group_scenario => [...group_scenario, scenario] )
                // setDropdownScenario(dropdown_scenario => dropdown_scenario.filter(item => item.id !== scanario.id))
                setDropdownScenario(dropdown_scenario => [...dropdown_scenario, scenario])
                setGroupScenario(group_scenario => group_scenario.filter(item => item.id !== scenario.id))
            }


        } catch (e) {
            setReceivedError(e)
        }
    }


    const quizDisableChange = async (disabled, id, type) => {
        //disabled == 1 means quiz disabled, 0 = means enabled
        const new_disabled = !disabled
        try {
            const data = await request(`/quiz/group_disable/${id}/${groupId}`, 'POST', {disabled: new_disabled}, {
                'x-access-token': token
            })
            console.log('error ', error);
            if (data.changed === 1) {
                setGroupScenario(
                    group_scenario => group_scenario.map(
                        item => {
                            if (type === 'default' && item.quiz && item.quiz.length) {
                                item.quiz = item.quiz.map(
                                    quiz_item => {
                                        if (quiz_item.id === id) {
                                            quiz_item.disabled = new_disabled ? 1 : 0
                                        }
                                        return quiz_item
                                    }
                                )
                            }

                            if (type === 'custom' && item.custom_quiz && item.custom_quiz.length) {
                                item.custom_quiz = item.custom_quiz.map(
                                    quiz_item => {
                                        if (quiz_item.id === id) {
                                            quiz_item.disabled = new_disabled ? 1 : 0
                                        }
                                        return quiz_item
                                    }
                                )
                            }

                            return item
                        }
                    )
                )
                if (role === 'admin') {
                    setDropdownScenario(
                        dropdown_scenario => dropdown_scenario.map(
                            item => {
                                if (item.quiz && item.quiz.length) {
                                    item.quiz = item.quiz.map(
                                        quiz_item => {
                                            if (quiz_item.id === id) {
                                                quiz_item.disabled = new_disabled ? 1 : 0
                                            }
                                            return quiz_item
                                        }
                                    )
                                }
                                return item
                            }
                        )
                    )
                }
            }
        } catch (e) {
            setReceivedError(e)
        }

    }


    const [quizName, setQuizName] = useState('')
    const [quizUpdateForm, setQuizUpdateForm] = useState([])

    const updateQuizHandler = (data, key, val) => {

        const temp = quizUpdateForm
        if (temp.some(item => item.id === data.id)) {
            console.log("Object found inside the array.");
            setQuizUpdateForm(
                quizUpdateForm => quizUpdateForm.map(
                    item => {
                        if (item.id === data.id) {
                            item[key] = val
                        }
                        return item
                    }
                )
            )

        } else {
            console.log("Object not found.");
            setQuizUpdateForm([...quizUpdateForm, data])
        }
    }

    const addQuiz = async (scenarioId) => {
        try {
            const data = await request('/quiz/add', 'POST', {
                name: quizName,
                scenarioId: scenarioId,
                custom: 1,
                groupId: groupId
            }, {
                'x-access-token': token
            })
            console.log('error ', error);
            if (data.quiz) {
                setGroupScenario(
                    group => group.map(
                        item => {
                            if (item.id === scenarioId)
                                if (item.custom_quiz && item.custom_quiz.length) {
                                    item.custom_quiz = [...item.custom_quiz, data.quiz[0]]
                                } else {
                                    item.custom_quiz = [data.quiz[0]]
                                }
                            return item;
                        }
                    )
                )
            }
        } catch (e) {
            setReceivedError(e)
        }
    }

    const changeQuiz = async (quiz_id) => {
        try {
            let data_form = quizUpdateForm.filter(item => {
                return item.id === quiz_id
            })
            const data = await request(`/quiz/update/${quiz_id}`, 'POST', data_form[0], {
                'x-access-token': token
            })
            console.log('error ', error);
            if (data.changed === 1) {
                //if request succes - change name
                console.log('name should be changed');
                setGroupScenario(
                    group => group.map(
                        item => {
                            item.custom_quiz.map(quiz_item => {
                                if (quiz_item.id === quiz_id) {
                                    quiz_item.name = data_form[0].name
                                }
                                return quiz_item
                            })

                            return item
                        }
                    )
                )
            }
            setQuizUpdateForm(quiz => quiz.filter(item => item.id !== quiz_id))
        } catch (e) {
            setReceivedError(e)
        }
    }

    const deleteQuiz = async (val) => {
        try {
            const data = await request(`/quiz/remove/${val}`, 'POST', null, {
                'x-access-token': token
            })
            console.log('error ', error);
            if (data.changed === 1) {
                // setContent(content => content.filter(item => item.id !== val))
                setGroupScenario(
                    group => group.map(
                        item => {
                            let new_quiz = item.custom_quiz.filter(quiz_item => quiz_item.id !== val).map((quiz_item, index) => {
                                quiz_item.index = index
                                return quiz_item
                            })
                            item.custom_quiz = new_quiz
                            return item;
                        }
                    )
                )
            }
        } catch (e) {
            setReceivedError(e)
        }
    }

    const changeQuizIndex = async (upperId, lowerId) => {
        try {
            const data = await request(`/content/index_change/${upperId}/${lowerId}`, 'POST', {type: 'quiz'}, {
                'x-access-token': token
            })
            console.log('error ', error);
            if (data.upper_changed === 1 && data.lower_changed === 1) {
                //if request success change name
                setGroupScenario(
                    group => group.map(
                        item => {
                            item.custom_quiz.map(quiz_item => {
                                if (quiz_item.id === upperId) {
                                    quiz_item.index = quiz_item.index - 1
                                }

                                if (quiz_item.id === lowerId) {
                                    quiz_item.index = quiz_item.index + 1
                                }

                                return quiz_item
                            })

                            return item
                        }
                    )
                )
            }

        } catch (e) {
            setReceivedError(e)
        }
    }

    const [passwordGeneratorUpdateForm, setPasswordGeneratorUpdateForm] = useState([])

    const updatePasswordGeneratorHandler = (data, key, val) => {

        if (passwordGeneratorUpdateForm.some(item => item.id === data.id)) {
            console.log("Object found inside the array.");
            setPasswordGeneratorUpdateForm(
                passwordGeneratorUpdateForm => passwordGeneratorUpdateForm.map(
                    item => {
                        if (item.id === data.id) {
                            item[key] = val
                        }
                        return item
                    }
                )
            )
        } else {
            console.log("Object not found.");
            setPasswordGeneratorUpdateForm([...passwordGeneratorUpdateForm, data])
        }
    }

    const [credentialsUpdateForm, setCredentialsUpdateForm] = useState([])

    const updateCredentialsHandler = (data, key, val) => {

        if (credentialsUpdateForm.some(item => item.id === data.id)) {
            console.log("Object found inside the array.");
            setCredentialsUpdateForm(
                credentialsUpdateForm => credentialsUpdateForm.map(
                    item => {
                        if (item.id === data.id) {
                            item[key] = val
                        }
                        return item
                    }
                )
            )
        } else {
            console.log("Object not found.");
            setCredentialsUpdateForm([...credentialsUpdateForm, data])
        }
    }

    const changeCredentials = async (credentials_id, scenarioId) => {
        try {
            let data_form = credentialsUpdateForm.filter(item => {
                return item.id === scenarioId
            })
            const data = await request(`/groups/update_credentials/${credentials_id}`, 'POST', data_form[0], {
                'x-access-token': token
            })
            console.log('error ', error);
            if (data.changed === 1) {
                //if request succes - change name
                console.log('name should be changed');
                setGroupScenario(
                    group => group.map(
                        item => {
                            if (item.id === scenarioId) {
                                item.scenario_login = data_form[0].login
                                item.scenario_password = data_form[0].password
                            }
                            return item
                        }
                    )
                )
            }
            setCredentialsUpdateForm(credentials => credentials.filter(item => item.id !== scenarioId))
        } catch (e) {
            setReceivedError(e)
        }
    }

    const generatePassword = async (credentialId) => {
        try {
            let data_form = passwordGeneratorUpdateForm.filter(item => {
                return item.id === credentialId
            })
            if (!data_form[0].amount) {
                data_form[0].amount = 1
            }
            if (!data_form[0].time_limit) {
                data_form[0].time_limit = '1h'
            }
            const data = await request(`/groups/generate_credentials/${credentialId}`, 'POST', data_form[0], {
                'x-access-token': token
            })
            console.log('error ', error);
            if (data.changed && data.changed > 0) {
                // alert(`Generated ${data.changed} passwords`)
                fetchPasswords(credentialId)
            }
            // setPasswordGeneratorUpdateForm(credentials => credentials.filter(item => item.id !== credentialId))
        } catch (e) {
            setReceivedError(e)
        }
    }

    const changeGroupLanguage = async (lang) => {
        const data = await request(`/groups/update_language/${groupId}/${lang}`, 'GET', null, {
            'x-access-token': token
        })
        console.log('error ', error);
        if (data.changed === 1) {
            setGroupLangCode(lang)
        }

    }

    const fetchPasswords = async (credentialId, scenario_login) => {

        console.log("--", credentialId, scenario_login);
        try {

            const fetched = await request(`/groups/generated_passwords/${credentialId}`, 'GET', null, {
                'x-access-token': token
            })

            if (fetched.passwords) {
                fetched.passwords = fetched.passwords.map(item => {
                    switch (item.interval) {
                        case '1h':
                            item.intervalFull = '1 Hour'
                            break;
                        case '5h':
                            item.intervalFull = '5 Hours'
                            break;
                        case '12h':
                            item.intervalFull = '12 Hours'
                            break;
                        case '24h':
                            item.intervalFull = '24 Hours'
                            break;
                        case '2d':
                            item.intervalFull = '2 Days'
                            break;
                        case '5d':
                            item.intervalFull = '5 Days'
                            break;
                        case '7d':
                            item.intervalFull = '7 Days'
                            break;
                        case '14d':
                            item.intervalFull = '14 Days'
                            break;
                        case '31d':
                            item.intervalFull = '31 Days'
                            break;
                        default:
                            item.intervalFull = '1 Hour'
                    }
                    return item
                })
                setPasswordsList({scenario_login: scenario_login, items: fetched.passwords, id: credentialId})
            }
        } catch (e) {
            setReceivedError(e)
        }
    }

    const handlePasswordsModalClose = () => {
        setPasswordsList({items: [], id: null});
    };

    return (
        <Container fluid>
            {(receivedError) ? <Toaster errors_list={receivedError} error_state={setReceivedError}/> : ''}
            {loading && <Loader/>}
            <h3 className="FormTitle">Content settings {role === "admin" ? "(" + groupName + ")" : null}</h3>

            {(role === 'admin' && dropdown_scenario && dropdown_scenario.length)
                ?
                <div>
                    <h3 className="FormSubTitle">Add Experience</h3>
                    <DropdownButton id="dropdown-basic-button" variant="info" title="Select"
                                    className="mr-2 BoxShadowSelect FormText">
                        {dropdown_scenario.map((item, index) =>
                            <Dropdown.Item key={item.id} onClick={() => addScenarioToGroup(item)}
                                           className="FormTextDark BoxShadow">
                                {activeLanguage.filter(lang => item["name_" + lang.code])
                                    .map(lang => item["name_" + lang.code]).join("|")}
                            </Dropdown.Item>
                        )}
                    </DropdownButton>
                </div>
                : (group_scenario && group_scenario.length ? <span/> :
                    <span className="mr-2 FormText">No Available Experiences</span>)
            }

            <br/>
            {(group_scenario && group_scenario.length)
                ?
                <div>
                    <h3 className="FormSubTitle">Experiences List</h3>
                    <div className="ml-2">
                        {group_scenario.map((item, index) =>
                            <div className="CardBlock BoxShadow mb-5" key={item.id}>
                                <Tabs className="CardBlockHeder" defaultActiveKey="passwords">
                                    <Tab
                                        disabled
                                        tabClassName="CardBlockHederTabName ml-3"
                                        title={<div className="">
                                            <span>{item["name_" + item.language]} ({item.language})</span>
                                        </div>}

                                    />

                                    <Tab
                                        eventKey="passwords"
                                        tabClassName=""
                                        title={<span className="FormTextDark">Passwords</span>}
                                    >
                                        <div className="m-3">
                                            <h6 className="FormSubTitle mb-2">General access password</h6>

                                            <div className="Table">
                                                <thead>
                                                <tr className="TableBlock">
                                                    <th className="TableFirstLineBlock">ExperienceID</th>
                                                    <th className="TableFirstLineBlock">Password</th>
                                                    <th className="TableFirstLineBlock">Action</th>
                                                </tr>
                                                </thead>

                                                <tbody>

                                                <tr onClick={() => {
                                                    let default_data = {
                                                        login: item.scenario_login,
                                                        password: item.scenario_password,
                                                        id: item.id
                                                    }
                                                    updateCredentialsHandler(default_data)
                                                }}>


                                                    <td className="TableSecondLineBlock TableSecondLineInput TableSecondLineText"
                                                        onKeyPress={(e => {
                                                            if (e.which === 13 || e.keyCode === 13) {
                                                                e.preventDefault()
                                                            }
                                                        })}
                                                        onInput={(e) => {
                                                            const val = e.currentTarget.textContent;
                                                            let data = item
                                                            updateCredentialsHandler(data, 'login', val);
                                                            updateCredentialsHandler(data, 'updated', 1);
                                                        }} contentEditable="true">
                                                        {(item.scenario_login)
                                                            ?
                                                            item.scenario_login
                                                            : 'empty'}
                                                    </td>


                                                    <td className="TableSecondLineBlock TableSecondLineInput TableSecondLineText"
                                                        onKeyPress={(e => {
                                                            if (e.which === 13 || e.keyCode === 13) {
                                                                e.preventDefault()
                                                            }
                                                        })}
                                                        onInput={(e) => {
                                                            const val = e.currentTarget.textContent;
                                                            let data = item
                                                            updateCredentialsHandler(data, 'password', val);
                                                            updateCredentialsHandler(data, 'updated', 1);
                                                        }} contentEditable="true">
                                                        {(item.scenario_password)
                                                            ?
                                                            item.scenario_password
                                                            : 'empty'}
                                                    </td>

                                                    <td className="TableSecondLineBlock ">
                                                        {credentialsUpdateForm.some(credentialsUpdateForm => credentialsUpdateForm.id === item.id && credentialsUpdateForm.updated === 1) &&
                                                        <img src="/img/save_white.png"
                                                             onClick={() => changeCredentials(item.scenario_credentials_id, item.id)}
                                                             className="TableSecondLineImg "/>}
                                                    </td>
                                                </tr>

                                                </tbody>
                                            </div>


                                            <h6 className="FormSubTitle mb-2 mt-5">Temporary Password Generator</h6>
                                            <Form className="my-2 " onClick={() => {
                                                updatePasswordGeneratorHandler({
                                                    id: item.scenario_credentials_id,
                                                    amount: 1,
                                                    time_limit: '1h'
                                                })
                                            }}>
                                                <Row className="d-flex align-items-center">
                                                    <Col>
                                                        <Form.Label className="FormText">Amount</Form.Label>
                                                        <Form.Control type="number" name="amount" className="FormTextDark BoxShadow" style={{fontWeight: "200"}}
                                                                      placeholder="Enter amount of passwords"
                                                                      onChange={e => updatePasswordGeneratorHandler({id: item.scenario_credentials_id}, 'amount', e.target.value)}/>
                                                    </Col>
                                                    <Col>
                                                        <Form.Label className="FormText">Time Limit</Form.Label>
                                                        <Form.Control as="select" custom
                                                                      onChange={(e) => {
                                                                          let select = e.target.value;
                                                                          updatePasswordGeneratorHandler({id: item.scenario_credentials_id}, 'time_limit', e.target.value)
                                                                      }
                                                                      }
                                                                      className="FormTextDark BoxShadow"  style={{
                                                            minWidth: '75px',
                                                            fontWeight: "200"
                                                        }}>
                                                            <option value="1h" className="FormTextDark">1 Hour</option>
                                                            <option value="5h"className="FormTextDark">5 Hours</option>
                                                            <option value="12h"className="FormTextDark">12 Hours</option>
                                                            <option value="24h"className="FormTextDark">24 Hours</option>
                                                            <option value="2d"className="FormTextDark">2 Days</option>
                                                            <option value="5d"className="FormTextDark">5 Days</option>
                                                            <option value="7d"className="FormTextDark">7 Days</option>
                                                            <option value="14d"className="FormTextDark">14 Days</option>
                                                            <option value="31d"className="FormTextDark">31 Days</option>
                                                        </Form.Control>
                                                    </Col>
                                                    <Col className="align-self-end d-flex">
                                                        <button type="submit" className="ElementButton SmallInputText" disabled={loading}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    generatePassword(item.scenario_credentials_id)
                                                                }}>
                                                            Generate
                                                        </button>

                                                        <button  disabled={loading} className="ElementButton SmallInputText mx-2"
                                                                onClick={() => fetchPasswords(item.scenario_credentials_id, item.scenario_login)}>
                                                            Show
                                                        </button>
                                                    </Col>
                                                </Row>
                                            </Form>

                                            {/* passwords_list.id === item.scenario_credentials_id
                                                &&
                                                <ListGroup className="ml-2 my-2">
                                                    {(passwords_list.items && passwords_list.items.length)
                                                        ?
                                                        passwords_list.items.map((password_item, index) =>
                                                            <ListGroup.Item key={index}>
                                                                {item.scenario_login + ' / ' + password_item.password + ' / ' + password_item.intervalFull}
                                                            </ListGroup.Item>
                                                        )
                                                        : 'No passwords'}
                                                </ListGroup>
                                                */}
                                        </div>
                                    </Tab>


                                    <Tab
                                        eventKey="quizzes"
                                        tabClassName=""
                                        title={<span className="FormTextDark">Quizzes</span>}
                                    ><Row className="m-3">
                                        <Col>
                                            <h6 className="FormSubTitle">Default Quizzes</h6>
                                        {(item.quiz && item.quiz.length)
                                            ?
                                            <ListGroup className="ml-2 my-2">

                                                {item.quiz.sort((a, b) => a.index - b.index).map((quiz_item, index) =>
                                                    <ListGroup.Item action key={quiz_item.id} className="ListItemStyled">
                                                        <span className="FormText">{quiz_item.name}</span>
                                                        {(quiz_item.disabled && quiz_item.disabled === 1)
                                                            ? <Badge onClick={() => {
                                                                quizDisableChange(1, quiz_item.id, 'default')
                                                            }} variant="danger" className="ml-2">Disabled</Badge>
                                                            : <Badge onClick={() => {
                                                                quizDisableChange(0, quiz_item.id, 'default')
                                                            }} variant="success" className="ml-2">Enabled</Badge>}
                                                    </ListGroup.Item>
                                                )}
                                            </ListGroup>
                                            : <span className="FormText">No quizzes</span>
                                        }
                                        </Col>
                                      <Col>

                                            <h6 className="FormSubTitle">Custom Quizzes</h6>
                                            <form className="mt-3 mb-2 FormBlock">
                                                <Row className="d-flex">
                                                    <Col>
                                                        <input className="FormInputSmall" type="text" name="quiz_name"
                                                                      placeholder="Enter quiz name.."
                                                                      onChange={e => setQuizName(e.target.value)}/>
                                                    </Col>
                                                    <Col className="align-self-end">
                                                        <button className="ElementButton" type="submit" variant="success" disabled={loading}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    addQuiz(item.id)
                                                                }}>
                                                            Add Quiz
                                                        </button>
                                                    </Col>
                                                </Row>
                                            </form>

                                            {item.custom_quiz && item.custom_quiz.sort((a, b) => a.index - b.index).map((quiz_item, index, array) =>
                                                <ListGroup.Item action key={quiz_item.id} className="ListItemStyled">
                                                    <div className="d-flex">
                                                        <div className="d-flex flex-column mr-3">
                                                            {array[index - 1] && <FaSortUp
                                                                onClick={() => changeQuizIndex(quiz_item.id, array[index - 1].id)}/>}
                                                            {array[index + 1] && <FaSortDown
                                                                onClick={() => changeQuizIndex(array[index + 1].id, quiz_item.id)}/>}
                                                        </div>
                                                        <span className="FormText">
                                                        <EditableSpan
                                                            key={quiz_item.id + index}
                                                            item={quiz_item}
                                                            updateHandler={updateQuizHandler}
                                                            fieldName={'name'}
                                                            langCode=''
                                                            textValue={quiz_item.name}
                                                        />
                                                        </span>
                                                        <div>
                                                        {quizUpdateForm.some(quizUpdateForm => quizUpdateForm.id === quiz_item.id && quizUpdateForm.updated === 1) &&
                                                        <img src="/img/save_white.png"  onClick={() => changeQuiz(quiz_item.id)}
                                                                className="CardBlockHederImgSmall mx-2 mt-1"/>}
                                                        <Link to={{
                                                            pathname: `/quiz/${quiz_item.id}`,
                                                            state: {customLanguage: item.language}
                                                        }}
                                                              className="ml-2"><img src="/img/Edit.png" className="CardBlockHederImgSmall mx-3"/></Link>

                                                      <img src="/img/TrashB.png" onClick={() => {
                                                            if (window.confirm('Delete this item?')) {
                                                                deleteQuiz(quiz_item.id)
                                                            }
                                                        }} className="CardBlockHederImgSmall mx-2 mt-1"/>

                                                        {(quiz_item.disabled && quiz_item.disabled === 1)
                                                            ? <Badge onClick={() => {
                                                                quizDisableChange(1, quiz_item.id, 'custom')
                                                            }} variant="danger" className="ml-2">Disabled</Badge>
                                                            : <Badge onClick={() => {
                                                                quizDisableChange(0, quiz_item.id, 'custom')
                                                            }} variant="success" className="ml-2">Enabled</Badge>}
                                                        </div>
                                                    </div>
                                                </ListGroup.Item>
                                            )}
                                          </Col>
                                        </Row>
                                    </Tab>

                                    <Tab
                                        eventKey="media"
                                        tabClassName=""
                                        title={<span className="FormTextDark">Media</span>}
                                    >
                                        {(item.video_360 || item.video_flat)
                                            ?
                                            <div className="m-3">
                                                <Row>
                                                    <Col>
                                                        <h6 className="FormSubTitle">360 Video</h6>
                                                        {item.video_360.length && item.video_360.map((video_item, index) =>
                                                            <ListGroup.Item action key={video_item.id} className="ListItemStyled">
                                                                <span className="FormText">{video_item.name}</span>
                                                                {video_item.file && video_item.file !== null &&
                                                                <a className="mx-2" target="_blank"
                                                                   rel="noopener noreferrer"
                                                                   href={`${process.env.REACT_APP_API}/static/video_360/${video_item.id}/${encodeURIComponent(video_item.file)}`}>
                                                                    <img src='/img/Edit.png' className="CardBlockHederImgSmall"/></a>}
                                                            </ListGroup.Item>
                                                        )}
                                                    </Col>

                                                    <Col>
                                                        <h6 className="FormSubTitle">Flat Video</h6>
                                                        {item.video_flat.length && item.video_flat.map((video_item, index) =>
                                                            <ListGroup.Item action key={video_item.id} className="ListItemStyled">
                                                                <span className="FormText">{video_item.name}</span>
                                                                {video_item.file && video_item.file !== null &&
                                                                <a className="mx-2" target="_blank"
                                                                   rel="noopener noreferrer"
                                                                   href={`${process.env.REACT_APP_API}/static/video_flat/${video_item.id}/${encodeURIComponent(video_item.file)}`}>
                                                                    <img src='/img/Edit.png' className="CardBlockHederImgSmall"/></a>}
                                                            </ListGroup.Item>
                                                        )}
                                                    </Col>
                                                </Row>

                                            </div>
                                            : ''}
                                    </Tab>

                                    <Tab
                                        tabClassName="CardBlockHederTabTresh"
                                        title={<div className=" ">
                                            {role === 'admin' && <img src="/img/Trash.png" onClick={() => {
                                                if (window.confirm('Delete this item?')) {
                                                    deleteScenarioFromGroup(item)
                                                }
                                            }} className="CardBlockHederImg "/>}
                                        </div>}
                                    />

                                </Tabs>
                            </div>
                        )}
                    </div>
                </div>
                : <span className="ml-2">Experiences not selected</span>}


            <h3 className="mt-5 FormTitle">User's Permissions</h3>
            <form className="FormBlock pb-3">
                <div className="mt-1 mb-2 FormBlockElement">

                    <input type="email" placeholder="Enter Email.." name="email" onChange={changeUserForm}
                           className="FormInputSmall mr-2 "/>
                    <input type="password" placeholder="Enter new password.." name="password" onChange={changeUserForm}
                           className="FormInputSmall mr-2 "/>
                    <button type="submit" className="ButtonBlockSmall mt-0" disabled={loading}
                            onClick={addUser}>
                        Add Moderator
                    </button>

                </div>
            </form>
            <div className="Table" bordered size="sm">
                <thead>
                <tr className="px-5">
                    {/*<th>#</th>*/}
                    <th className="TableFirstLineBlock">Email</th>
                    <th className="TableFirstLineBlock">Role</th>
                    <th className="TableFirstLineBlock">Action</th>
                    {/*<th>Active</th>*/}
                </tr>
                </thead>

                <tbody>
                {(users && users.length)
                    ? users.map((user, index) =>
                        <tr className="TableBlock" key={user.id} onClick={() => {
                            updateUserHandler({
                                id: user.id,
                                password: user.password,
                                email: user.email,
                                role: user.role
                            })
                        }}>
                            {/*<td>{index + 1}</td>*/}
                            <td className="TableSecondLineBlock CenterContent d-flex FormText"
                                onKeyPress={(e => {
                                    if (e.which === 13 || e.keyCode === 13) {
                                        e.preventDefault()
                                    }
                                })}
                                onInput={(e) => {
                                    const val = e.currentTarget.textContent;
                                    let data = user
                                    updateUserHandler(data, 'email', val);
                                    updateUserHandler(data, 'updated', 1);
                                }} contentEditable="true">
                                {user.email}
                            </td>
                            <td className="TableSecondLineBlock ">

                                <Form.Control className="BoxShadow" as="select" custom
                                              onChange={(e) => {
                                                  let select = e.target.value;
                                                  let data = user
                                                  updateUserHandler(data, 'role', select);
                                                  updateUserHandler(data, 'updated', 1);
                                              }}
                                              style={{
                                                  minWidth: '75px',
                                                  fontWeight: '200',
                                                  fontSize: '22px'
                                              }}
                                              defaultValue={user.role}>
                                    <option className="FormTextDark" disabled> -- select an option --</option>
                                    <option className="FormTextDark">moderator</option>
                                    <option className="FormTextDark">analytic</option>
                                </Form.Control>

                            </td>
                            <td className="TableSecondLineBlock">
                                {userUpdateForm.some(userUpdateForm => userUpdateForm.id === user.id && userUpdateForm.updated === 1)
                                && <img src="/img/save_white.png" onClick={() => changeUser(user.id)}
                                        className="CardBlockHederImgSmall mx-3 "/>}
                                <img src="/img/TrashB.png" onClick={() => {
                                    if (window.confirm('Delete this item?')) {
                                        deleteUser(user.id)
                                    }
                                }}
                                     className="CardBlockHederImgSmall mx-1"/>
                            </td>
                            {/*
                      <td>
                        {user.active
                          ? <Badge onClick={()=>{userActivationChange(user.active, user.id)}} variant="success" className="ml-2">True</Badge>
                          : <Badge onClick={()=>{userActivationChange(user.active, user.id)}} variant="danger" className="ml-2">False</Badge>}
                      </td>
                    */}
                        </tr>
                    )
                    : <tr>
                        <td></td>
                        <td>No users</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>}
                </tbody>
            </div>
            {/*
              <DropdownButton id="dropdown-basic-button" variant="primary" title="Client Language"
                              className="mx-2">
                  {activeLanguage.map((item, index) =>
                      <Dropdown.Item key={index} onClick={() => changeGroupLanguage(item.code)}>
                          {item.name} {groupLangCode === item.code &&
                      <Badge variant="success" className="ml-2">Active</Badge>}
                      </Dropdown.Item>
                  )}
              </DropdownButton>
              */
            }

            <Modal show={passwords_list.items && passwords_list.items.length} onHide={handlePasswordsModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="FormTextDark">Unused temporary passwords</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="FormTextDark">Login | Password | Time Limit</div>
                    {passwords_list.items.map((password_item, index) =>
                        <div key={index} className="FormTextDark">
                            {passwords_list.scenario_login + ' | ' + password_item.password + ' | ' + password_item.intervalFull}
                        </div>
                    )}

                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>

        </Container>

    )
}

import React, {useContext, useEffect, useState, useCallback} from 'react'
import {useHttp} from '../hooks/http.hook'
import {AuthContext} from '../context/AuthContext'
import { LanguagesContext } from "../context/LanguagesContext";

import { Button, Form } from 'react-bootstrap';
import {Toaster} from '../components/Toaster'

import "./AuthPage.css";

export const AuthPage = () => {

    const auth = useContext(AuthContext)
    const languages = useContext(LanguagesContext);
    const {loading, request, error} = useHttp()
    const [form, setForm] = useState({email: '', password: ''})

    // const [receivedError, setReceivedError] = useState(null)

    const [receivedError, setReceivedError] = useState(error)
    if (error)
        console.log('login error HERE', error);

    const fetchActiveLanguage = useCallback(async () => {
        try {
            const fetched = await request('/settings/language/active', 'GET', null, {
                // 'x-access-token': token
            })

            if (fetched.languages) {
                languages.initList(fetched.languages);
                if (fetched.languages.filter(item => item.code == languages.pageLanguage).length === 0)
                    languages.changePageLanguage('en');
                console.log("activeList:", fetched.languages);
            }

        } catch (e) {
            setReceivedError(e)
        }
    }, [])


    useEffect(() => {
        fetchActiveLanguage()
    }, [fetchActiveLanguage])


    const changeHandler = event => {
        setForm({...form, [event.target.name]: event.target.value})
    }

    const loginHandler = async () => {
        try {

            const data = await request('/user/login_auth', 'POST', {...form})
            auth.login(data.token, data.exp, data.username, data.role, data.group)

        } catch (e) {

            setReceivedError(e)
        }
    }

    return (
        <React.Fragment>
            {(receivedError) ? <Toaster errors_list={receivedError} error_state={setReceivedError}/> : ''}
            <div className="ContentBlock">
                <div className="InnerBlock">
                    <div className="ImgBlock">
                        <img
                            className="App-logo"
                            src="/img/OthrReality-Logo.png"
                        />
                    </div>
                    <form className="FormBlocks">

                            <input className="InputBlock" type="email" name="email" placeholder="Email" value={form.email}
                                          onChange={changeHandler}/>



                            <input className="InputBlock" type="password" name="password" placeholder="Password" value={form.password}
                                          onChange={changeHandler}/>

                        <button className={`ButtonBlock ${loading && "ButtonBlockDisabled"}`}
                                variant="primary" type="submit" disabled={loading}
                                onClick={loginHandler}>
                            Login
                        </button>
                    </form>
                </div>
            </div>

        </React.Fragment>


    )
}

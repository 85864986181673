import React, {useCallback, useContext, useEffect, useState} from 'react'

import {useHttp} from '../../hooks/http.hook'
import {AuthContext} from '../../context/AuthContext'
import { LanguagesContext } from "../../context/LanguagesContext";

import {Loader} from '../../components/Loader'
import {Toaster} from '../../components/Toaster'

import { Container, Row, Col, Tabs, Tab, Badge, Dropdown, DropdownButton, ListGroup, Button, Alert} from 'react-bootstrap';

import jsonLanguages from '../../languages.json'
import './ContentPage.css';


export const SettingsPage = () => {
    const {token} = useContext(AuthContext)
    const {pageLanguage, activeLanguage, initList} = useContext(LanguagesContext);
    const [settingsLanguage, setSettingsLanguage] = useState([])
    const [dropdownLanguages, setDropdownLanguages] = useState(jsonLanguages)
    const {request, loading, error} = useHttp()
    const [receivedError, setReceivedError] = useState(error)

    const fetchSettingsLanguage = useCallback(async () => {
        try {
            const fetched = await request('/settings/language', 'GET', null, {
                'x-access-token': token
            })
            if (fetched.languages) {
                setSettingsLanguage(fetched.languages)

                fetched.languages.map(
                    item => {
                        console.log(item);
                        setDropdownLanguages(dropdownLanguages => dropdownLanguages.filter(dropdown_item => dropdown_item.code !== item.code))
                        return item;
                    }
                )
            }
            // console.log(settingsLanguage);

        } catch (e) {
            setReceivedError(e)
        }
    }, [token, request])

    useEffect(() => {
        fetchSettingsLanguage()
    }, [fetchSettingsLanguage])

    const [showAlert, setShowAlert] = useState(false)
    // const [groupId, setGroupId] = useState('')
    const [groups, setGroups] = useState([])
    const fetchGroups = useCallback(async () => {
        try {
            const fetched = await request('/groups', 'GET', null, {
                'x-access-token': token
            })
            if (fetched.groups) {
                console.log(fetched.groups);
                setGroups(fetched.groups)
            }

        } catch (e) {
            setReceivedError(e)
        }
    }, [token, request])

    useEffect(() => {
        fetchGroups()
    }, [fetchGroups])

    const deleteStatistic = async (id) => {
        console.log('delete triggered for', id);
        try {
            const fetched = await request('/statistic/remove', 'POST', {groupId: id}, {
                'x-access-token': token
            })
            if (fetched.status === 'success') {
                setShowAlert(true)
            }
            console.log('fetchted', fetched);
        } catch (e) {
            setReceivedError(e)
        }
    }

    useEffect(() => {
        fetchGroups()
    }, [fetchGroups])

    const languageActivate = async (id, active) => {
        const new_active = !active
        try {
            const data = await request(`/settings/language/activate/${id}`, 'POST', {active: new_active}, {
                'x-access-token': token
            })
            console.log('error ', error);
            if (data.changed === 1) {

                setSettingsLanguage(
                    settingsLanguage => settingsLanguage.map(
                        item => {
                            if (item.id === id) {
                                item.active = new_active
                            }
                            return item;
                        }
                    )
                )
            }
        } catch (e) {
            setReceivedError(e)
        }

    }

    const addLanguage = async (name, code) => {
        try {
            const data = await request('/settings/language/add', 'POST', {name: name, code: code}, {
                'x-access-token': token
            })
            console.log('error ', error);
            if (data.language) {
                setSettingsLanguage(settingsLanguage => [...settingsLanguage, data.language[0]])
                setDropdownLanguages(dropdownLanguages => dropdownLanguages.filter(item => item.code !== code))
            }

        } catch (e) {
            setReceivedError(e)
        }
    }


    return (
        <Container>
            {(receivedError) ? <Toaster errors_list={receivedError} error_state={setReceivedError}/> : ''}
            {loading && <Loader/>}

            <h3 className="FormTitle">Settings page</h3>
            <div className="CardBlock BoxShadow mb-5">
            <Tabs defaultActiveKey="languages" id="uncontrolled-tab-example"
                  className="CardBlockHeder" >
                <Tab eventKey="languages" tabClassName="CardBlockHederTab"
                     title={
                   <div style={{fontWeight: "200"}}>Languages</div>
                }>
                    <div className="FormBlockElement p-4 ">

                        <div>
                            <h5 className="FormSubTitle ">Current list</h5>
                            <ListGroup className="">

                                {(settingsLanguage && settingsLanguage.length)
                                    ?
                                    settingsLanguage.map((item, index) =>
                                        <ListGroup.Item key={item.id} className="ListItemStyled">
                                            <span className="FormText">{item.name}</span>
                                            {item.code !== 'en' && (item.active ? <Badge onClick={() => {
                                                    languageActivate(item.id, item.active)
                                                }} variant="success" className="ml-2">Active</Badge> :
                                                <Badge onClick={() => {
                                                    languageActivate(item.id, item.active)
                                                }} variant="danger" className="ml-2">Inactive</Badge>)}
                                            {item.code === 'en' &&
                                            <Badge variant="primary" className="ml-2">default</Badge>}
                                        </ListGroup.Item>
                                    )

                                    : <span className="ml-2">No added languages</span>}
                            </ListGroup>
                        </div>
                        <div className="ml-5">
                            <h5 className="FormSubTitle ">Add new</h5>
                            {(dropdownLanguages && dropdownLanguages.length)
                                ?
                                <DropdownButton id="dropdown-basic-button" variant="info" title={<span>Languages</span>}
                                                className="mr-2">
                                    {dropdownLanguages.map((item, index) =>
                                        <Dropdown.Item key={index}
                                                       onClick={
                                                           () => addLanguage(item.name, item.code)}>{item.name}</Dropdown.Item>
                                    )}
                                </DropdownButton>

                                : <span className="mr-2">No Available Languages</span>}
                        </div>

                    </div>
                </Tab>

                <Tab eventKey="statistic" title={<span style={{fontWeight: "200"}}>Statistics</span>} className="p-4" tabClassName="CardBlockHederTab">
                    {false && showAlert &&
                    <Alert variant="success" className="mx-1" onClose={() => setShowAlert(false)} dismissible>
                        <Alert.Heading>Data deleted</Alert.Heading>
                    </Alert>}
                    {(groups && groups.length)
                        ?
                        <div>
                            <h4 className="FormSubTitle">Choose Client to erase user's activity data:</h4>
                        <DropdownButton id="dropdown-basic-button" variant="info"
                                        title="Companies" className="d-inline">
                            {groups.map((item, index) =>

                                <Dropdown.Item key={index} onClick={() => {
                                    if (window.confirm('Delete ALL statistics data for this Client?')) {
                                        deleteStatistic(item.id)
                                    }
                                }}>{item.name}</Dropdown.Item>
                            )}


                        </DropdownButton>
                        </div>
                        : <span className="mr-2">No Groups</span>}
                    <br/><br/>
                    <h3 className="FormSubTitle">Erase All statistics data from server:</h3>
                    <Button variant="danger" disabled={loading}
                            onClick={() => {
                                if (window.confirm('DELETE ALL DATA FROM THE SERVER?')) {
                                    if (window.confirm('Data can not be restored. Are you sure?'))
                                        deleteStatistic(null);
                                }
                            }}>
                        Clear All
                    </Button>

                </Tab>

            </Tabs>
            </div>
        </Container>
    )
}

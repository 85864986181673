import {createContext} from 'react'

function noop() {}

export const LanguagesContext = createContext({
    pageLanguage: 'en',
    changePageLanguage: noop,
    activeLanguage: [],
    initList: noop
});

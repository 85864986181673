import React, { useState } from 'react'
import Toast from 'react-bootstrap/Toast'
import styles from './loader.module.css';

const mystyle = {
  position: 'absolute',
  top: '30%',
    left: '35%',
    right: '0'
};

export const Toaster = ({ errors_list, error_state }) => {
  const [show, setShow] = useState(true);
  //console.log('Toaster errror', errors_list);
  // console.log(errors_list.errors.length);
  if ( errors_list && errors_list.errors === undefined ) {
    return <p className="alert alert-danger">Some critical error appear..</p>
  }

    console.log("errors_list:",errors_list);

  return(
      <div className={styles.loader}>
      <Toast  style={mystyle} onClose={() =>{setShow(false); error_state(false)} } show={show} delay={3000} animation={true} autohide>
        <Toast.Header >
          <strong className="mr-auto text-danger">{

             errors_list.errors ?
                 ( errors_list.errors.length ? errors_list.errors.map(m=> <div>{m.msg}</div>)
                     : JSON.stringify(errors_list.message) ) : "Some error appear.."

          }</strong>
        </Toast.Header>
        { errors_list.errors &&
          <Toast.Body className="alert alert-danger">
            { (errors_list.errors && errors_list.errors.length) ? errors_list.errors.map(error=><p>{error.msg}</p>) : ''}
          </Toast.Body>
        }
      </Toast>
      </div>

  )
}



// class Toaster extends Component {
//   render() {
//     return (
//       <div className="alert alert-danger"><span>Toaster Here</span></div>
//     );
//   }
// }
//
// export default Toaster;

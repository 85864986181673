import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useHttp} from '../../hooks/http.hook'
import {AuthContext} from '../../context/AuthContext'
import {Loader} from '../../components/Loader'
import {Toaster} from '../../components/Toaster'
import {Link} from 'react-router-dom'
import { Button, Form, Row, Col, Table} from 'react-bootstrap';
import { FaTrashAlt, FaSave, FaExternalLinkAlt } from 'react-icons/fa';

import './ContentPage.css';

export const GroupsPage = () => {
    const {token} = useContext(AuthContext)
    const [groups, setGroups] = useState([])
    const {request, loading, error} = useHttp()
    const [receivedError, setReceivedError] = useState(error)

    const [name, setName] = useState('')

    const [groupUpdateForm, setGroupUpdateForm] = useState([])

    const updateGroupHandler = (data, key, val) => {
        const temp = groupUpdateForm
        if (temp.some(item => item.id === data.id)) {
            console.log("Object found inside the array.");
            setGroupUpdateForm(
                groupUpdateForm => groupUpdateForm.map(
                    item => {

                        if (item.id === data.id) {
                            item[key] = val
                        }
                        return item
                    }
                )
            )
        } else {
            console.log("Object not found.");
            setGroupUpdateForm([...groupUpdateForm, data])
        }
    }

    const fetchGroups = useCallback(async () => {
        try {
            const fetched = await request('/groups', 'GET', null, {
                'x-access-token': token
            })
            if (fetched.groups) {
                console.log(fetched.groups);
                setGroups(fetched.groups)
            }

        } catch (e) {
            setReceivedError(e)
        }
    }, [token, request])

    useEffect(() => {
        fetchGroups()
    }, [fetchGroups])

    const deleteGroup = async (val) => {

        try {
            const data = await request(`/groups/remove/${val}`, 'POST', null, {
                'x-access-token': token
            })
            console.log('error ', error);
            if (data.changed === 1) {
                setGroups(groups => groups.filter(item => item.id !== val))
            }

            // setGroups(groups => [...groups, test_group] )

            // history.push(`/detail/${data.link._id}`)
        } catch (e) {
            setReceivedError(e)
        }


    }

    const changeGroup = async (group_id) => {
        let data_form = groupUpdateForm.filter(item => {
            return item.id === group_id
        })
        try {
            const data = await request(`/groups/update/${group_id}`, 'POST', data_form[0], {
                'x-access-token': token
            })
            console.log('error ', error);
            if (data.changed === 1) {
                //if request success change name
                console.log('name should be changed');

                setGroups(
                    groups => groups.map(
                        item => {
                            if (item.id === group_id)
                                item.name = data_form[0].name
                            return item;
                        }
                    )
                )
            }
            setGroupUpdateForm(group => group.filter(item => item.id !== group_id))
        } catch (e) {
            setReceivedError(e)
        }
    }


    const addGroup = async () => {
        try {
            const data = await request('/groups/add', 'POST', {name: name}, {
                'x-access-token': token
            })
            console.log('error ', error);
            if (data.group) {
                setGroups(groups => [...groups, data.group[0]])
            }

        } catch (e) {
            setReceivedError(e)
        }
    }

    return (
        <>
            {(receivedError) ? <Toaster errors_list={receivedError} error_state={setReceivedError}/> : ''}
            {loading && <Loader/>}

            <h3 className="FormTitle">Clients</h3>

            <form className="FormBlock">
                <Row className="d-flex">
                    <input type="text" placeholder="Enter name.." className="FormInput BoxShadow" name="group_name"
                           onChange={e => setName(e.target.value)}/>
                    <button type="submit" className="ElementButton" disabled={loading}
                            onClick={addGroup}>
                        Add Client
                    </button>
                </Row>
            </form>

            <div className="Table">
                <thead>
                <tr className="TableBlock">
                    <th className="TableFirstLineBlock">#</th>
                    <th className="TableFirstLineBlock">Name</th>
                    <th className="TableFirstLineBlock">Details</th>
                    <th className="TableFirstLineBlock">Action</th>
                </tr>
                </thead>

                <tbody>
                {(groups && groups.length)
                    ? groups.map((group, index) =>
                        <tr key={group.id}
                            onClick={() => {
                                let default_data = {id: group.id, name: group.name}
                                updateGroupHandler(default_data)
                            }}
                        >
                            <td className="TableSecondLineBlock TableSecondLineText d-flex CenterContent">
                                <div className="d-flex CenterContent">
                                {index+1}
                                </div>
                                </td>
                            <td className="TableSecondLineBlock TableSecondLineInput TableSecondLineText"
                                onKeyPress={(e => {
                                    if (e.which === 13 || e.keyCode === 13) {
                                        e.preventDefault()
                                    }
                                })}
                                onInput={(e) => {
                                    const val = e.currentTarget.textContent;
                                    // updateGroupHandler(groupUpdateForm => {
                                    //   return { ...groupUpdateForm, name: val }
                                    // });
                                    let data = group
                                    // data.updated = 1
                                    updateGroupHandler(data, 'name', val);
                                    updateGroupHandler(data, 'updated', 1);
                                }} contentEditable="true">
                                {group.name}
                            </td>
                            <td className="TableSecondLineBlock d-flex  CenterContent ">
                                <Link to={`/client/${group.id}`} className=" ml-2">
                                    <img src="/img/Edit.png" className="TableSecondLineImg mx-2"/>
                                </Link>
                            </td>
                            <td className="TableSecondLineBlock  ">
                              <div className="d-flex CenterContent">
                                {groupUpdateForm.some(groupUpdateForm => groupUpdateForm.id === group.id && groupUpdateForm.updated === 1)
                                && <img src="/img/save_white.png" onClick={() => changeGroup(group.id, group.name)}
                                           className="TableSecondLineImg mx-3"/>}

                                <img
                                    src="/img/TrashB.png" onClick={() => {
                                    if (window.confirm('Delete this item?')) {
                                        deleteGroup(group.id)
                                    }
                                }}
                                    className="TableSecondLineImg mx-2"/>
                              </div>
                            </td>
                        </tr>
                    )
                    : <tr>
                        <td> </td>
                        <td>No clients</td>
                        <td> </td>
                        <td> </td>
                    </tr>}
                </tbody>
            </div>


        </>
    )
}

import {createContext} from 'react'

function noop() {}

export const AuthContext = createContext({
  token: null,
  tokenExp: null,
  role: null,
  username: null,
  group: null,
  login: noop,
  logout: noop,
  isAuthenticated: false,
    pageLanguage: 'en',
    changePageLanguage: noop
})

import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useHttp} from '../../hooks/http.hook'
import {AuthContext} from '../../context/AuthContext'
import { LanguagesContext } from "../../context/LanguagesContext";

import {Loader} from '../../components/Loader'
import {Toaster} from '../../components/Toaster'
import {Link} from 'react-router-dom'

import { Button, Form, Row, Col, Card, Accordion, Tabs, Tab, Badge, Table} from 'react-bootstrap';
import { FaTrashAlt, FaSave, FaExternalLinkAlt, FaSortUp, FaSortDown, FaFileUpload } from 'react-icons/fa';
import { IconContext } from "react-icons";
import EditableSpan from "../../components/EditableSpan";

import './ContentPage.css';

export const ContentPage = () => {
    const {token} = useContext(AuthContext)
    const {pageLanguage, activeLanguage} = useContext(LanguagesContext);

    const [content, setContent] = useState([])
    const {request, loading, error, progress, abortUpload} = useHttp()
    const [receivedError, setReceivedError] = useState(error)
    //const [activeLanguage, setActiveLanguage] = useState([])

    //const [pageLanguage, setPageLanguage] = useState('en')

    const [formScenario, setFormScenario] = useState({name_en: ''})
    const [scenarioUpdateForm, setScenarioUpdateForm] = useState([])

    const [quizName, setQuizName] = useState('')
    const [quizUpdateForm, setQuizUpdateForm] = useState([])

    const [videoUpdateForm, setVideoUpdateForm] = useState([])
    const [videoFlatUpdateForm, setVideoFlatUpdateForm] = useState([])

    const [formVideo, setFormVideo] = useState({key: ''})
    const [formFlatVideo, setFormFlatVideo] = useState({key: ''})

    const updateScenarioHandler = (data, key, val) => {
        const temp = scenarioUpdateForm
        if (temp.some(item => item.id === data.id)) {
            //console.log("Object found inside the array.");
            setScenarioUpdateForm(
                scenarioUpdateForm => scenarioUpdateForm.map(
                    item => {
                        if (item.id === data.id) {
                            item[key] = val
                        }
                        return item
                    }
                )
            )

        } else {
            //console.log("Object not found.");
            setScenarioUpdateForm([...scenarioUpdateForm, data])
        }

    }


    const updateQuizHandler = (data, key, val) => {

        const temp = quizUpdateForm
        if (temp.some(item => item.id === data.id)) {
            console.log("Object found inside the array.");
            setQuizUpdateForm(
                quizUpdateForm => quizUpdateForm.map(
                    item => {
                        if (item.id === data.id) {
                            item[key] = val
                        }
                        return item
                    }
                )
            )

        } else {
            console.log("Object not found.");
            setQuizUpdateForm([...quizUpdateForm, data])
        }
    }

    const changeHandler = event => {
        setFormScenario({...formScenario, [event.target.name]: event.target.value, language: pageLanguage})
    }

    /*  const fetchActiveLanguage = useCallback(async () => {
        try {
          const fetched = await request('/settings/language/active', 'GET', null, {
            'x-access-token': token
          })

          if (fetched.languages) {
            setActiveLanguage(fetched.languages)
          }
          // console.log(settingsLanguage);

        } catch (e) {setReceivedError(e)}
      }, [token, request])
    */
    /*
      useEffect(() => {
        fetchActiveLanguage()
      }, [fetchActiveLanguage])
    */

    const fetchContent = useCallback(async () => {
        try {
            const fetched = await request('/content', 'GET', null, {
                'x-access-token': token
            })
            if (fetched.content) {
                console.log(fetched.content);
                setContent(fetched.content)
            }

        } catch (e) {
            setReceivedError(e)
        }
    }, [token, request])

    useEffect(() => {
        fetchContent()
    }, [fetchContent])


    const deleteScenario = async (val) => {
        try {
            const data = await request(`/scenario/remove/${val}`, 'POST', null, {
                'x-access-token': token
            })
            console.log('error ', error);
            if (data.changed === 1) {
                setContent(content => content.filter(item => item.id !== val).map((item, index) => {
                    item.index = index
                    return item
                }))
            }
        } catch (e) {
            setReceivedError(e)
        }
    }

    const deleteQuiz = async (val) => {
        try {
            const data = await request(`/quiz/remove/${val}`, 'POST', null, {
                'x-access-token': token
            })
            console.log('error ', error);
            if (data.changed === 1) {
                // setContent(content => content.filter(item => item.id !== val))
                setContent(
                    content => content.map(
                        item => {
                            let new_quiz = item.quiz.filter(quiz_item => quiz_item.id !== val).map((quiz_item, index) => {
                                quiz_item.index = index
                                return quiz_item
                            })
                            item.quiz = new_quiz
                            return item;
                        }
                    )
                )
            }
        } catch (e) {
            setReceivedError(e)
        }
    }

    const changeScenario = async (scenario_id) => {
        console.log('form to send', scenarioUpdateForm);

        let data_form = scenarioUpdateForm.filter(item => {
            return item.id === scenario_id
        })
        try {
            const data = await request(`/scenario/update/${scenario_id}`, 'POST', data_form[0], {
                'x-access-token': token
            })
            console.log('error ', error);
            if (data.changed === 1) {
                //if request failed changed name to default
                console.log('name should be changed');
                setContent(
                    content => content.map(
                        item => {

                            if (item.id === scenario_id) {
                                activeLanguage.map((lang_item, index) =>
                                    item['name_' + lang_item.code] = data_form[0]['name_' + lang_item.code]
                                )
                            }

                            return item
                        }
                    )
                )
            }
            setScenarioUpdateForm(scenario => scenario.filter(item => item.id !== scenario_id))
        } catch (e) {
            setReceivedError(e)
        }
    }

    const changeQuiz = async (quiz_id) => {
        try {
            let data_form = quizUpdateForm.filter(item => {
                return item.id === quiz_id
            })
            const data = await request(`/quiz/update/${quiz_id}`, 'POST', data_form[0], {
                'x-access-token': token
            })
            console.log('error ', error);
            if (data.changed === 1) {
                //if request succes - change name
                console.log('name should be changed');
                setContent(
                    content => content.map(
                        item => {
                            item.quiz.map(quiz_item => {
                                if (quiz_item.id === quiz_id) {
                                    quiz_item.name = data_form[0].name
                                }
                                return quiz_item
                            })

                            return item
                        }
                    )
                )
            }
            setQuizUpdateForm(quiz => quiz.filter(item => item.id !== quiz_id))
        } catch (e) {
            setReceivedError(e)
        }
    }

    const addScenario = async () => {
        try {
            const data = await request('/scenario/add', 'POST', formScenario, {
                'x-access-token': token
            })
            setFormScenario(null)
            console.log('error ', error);
            if (data.scenario) {
                setContent(content => [...content, data.scenario[0]])
            }
        } catch (e) {
            setReceivedError(e)
        }
    }

    const addQuiz = async (scenarioId) => {
        try {
            const data = await request('/quiz/add', 'POST', {name: quizName, scenarioId: scenarioId}, {
                'x-access-token': token
            })
            console.log('error ', error);
            if (data.quiz) {
                // setContent(content => [...content, data.scenario[0]] )
                setContent(
                    content => content.map(
                        item => {
                            if (item.id === scenarioId)
                                if (item.quiz && item.quiz.length) {
                                    item.quiz = [...item.quiz, data.quiz[0]]
                                } else {
                                    item.quiz = [data.quiz[0]]
                                }
                            // item.quiz = [...item.quiz, data.quiz[0]]
                            return item;
                        }
                    )
                )
            }
        } catch (e) {
            setReceivedError(e)
        }
    }


    const changeIndex = async (upperId, lowerId) => {
        try {
            const data = await request(`/content/index_change/${upperId}/${lowerId}`, 'POST', {type: 'scenario'}, {
                'x-access-token': token
            })
            console.log('error ', error);
            if (data.upper_changed === 1 && data.lower_changed === 1) {
                //if request success change name
                console.log('name should be changed');

                setContent(
                    content => content.map(
                        item => {
                            if (item.id === upperId) {
                                item.index = item.index - 1
                            }

                            if (item.id === lowerId) {
                                item.index = item.index + 1
                            }

                            return item
                        }
                    )
                )
            }

        } catch (e) {
            setReceivedError(e)
        }
    }

    const changeQuizIndex = async (upperId, lowerId) => {
        try {
            const data = await request(`/content/index_change/${upperId}/${lowerId}`, 'POST', {type: 'quiz'}, {
                'x-access-token': token
            })
            console.log('error ', error);
            if (data.upper_changed === 1 && data.lower_changed === 1) {
                //if request success change name
                setContent(
                    content => content.map(
                        item => {
                            item.quiz.map(quiz_item => {
                                if (quiz_item.id === upperId) {
                                    quiz_item.index = quiz_item.index - 1
                                }

                                if (quiz_item.id === lowerId) {
                                    quiz_item.index = quiz_item.index + 1
                                }

                                return quiz_item
                            })

                            return item
                        }
                    )
                )
            }

        } catch (e) {
            setReceivedError(e)
        }
    }


    const changeVideoHandler = (event, type) => {
        if (type === '360') {
            setFormVideo({...formVideo, [event.target.name]: event.target.value})
        } else {
            setFormFlatVideo({...formFlatVideo, [event.target.name]: event.target.value})
        }

    }

    const updateVideoHandler = (type, data, key, val) => {
        // const temp = videoUpdateForm
        let temp

        if (type === '360') {
            temp = videoUpdateForm
        } else {
            temp = videoFlatUpdateForm
        }

        if (temp.some(item => item.id === data.id)) {
            console.log("Object found inside the array.");
            if (type === '360') {
                setVideoUpdateForm(
                    videoUpdateForm => videoUpdateForm.map(
                        item => {
                            if (item.id === data.id) {
                                item[key] = val
                            }
                            return item
                        }
                    )
                )
            } else {
                setVideoFlatUpdateForm(
                    videoFlatUpdateForm => videoFlatUpdateForm.map(
                        item => {
                            if (item.id === data.id) {
                                item[key] = val
                            }
                            return item
                        }
                    )
                )
            }
        } else {
            console.log("Object not found.");
            if (type === '360') {
                setVideoUpdateForm([...videoUpdateForm, data])
            } else {
                setVideoFlatUpdateForm([...videoFlatUpdateForm, data])
            }
        }
    }


    const addVideo = async (video_type, scenarioId) => {
        try {
            let form
            if (video_type === '360') {
                form = formVideo
            } else {
                form = formFlatVideo
            }
            form.type = video_type
            let data = await request(`/video/add/${scenarioId}`, 'POST', form, {
                'x-access-token': token
            })
            if (data.video) {
                setContent(
                    content => content.map(
                        item => {
                            if (item.id === scenarioId)
                                if (item['video_' + video_type] && item['video_' + video_type].length) {
                                    item['video_' + video_type] = [...item['video_' + video_type], data.video[0]]
                                } else {
                                    item['video_' + video_type] = [data.video[0]]
                                }
                            return item;
                        }
                    )
                )
            }
        } catch (e) {
            setReceivedError(e)
        }
    }

    const changeVideo = async (type, video_id) => {
        try {
            let temp
            if (type === '360') {
                temp = videoUpdateForm
            } else {
                temp = videoFlatUpdateForm
            }

            let data_form = temp.filter(item => {
                return item.id === video_id
            })


            let data = await request(`/video/update_${type}/${video_id}`, 'POST', data_form[0], {
                'x-access-token': token
            })

            console.log('error ', error);
            if (data.changed === 1) {
                //if request success change video
                setContent(
                    content => content.map(
                        item => {
                            item['video_' + type] = item['video_' + type].map(video_item => {
                                if (video_item.id === video_id) {
                                    // video_item.name = data_form[0].name
                                    // video_item.type = data_form[0].type
                                    // video_item.split = data_form[0].split
                                    // video_item.mode = data_form[0].mode
                                    video_item = data_form[0]
                                }
                                return video_item
                            })

                            return item
                        }
                    )
                )
            }
            if (type === '360') {
                setVideoUpdateForm(video => video.filter(item => item.id !== video_id))
            } else {
                setVideoFlatUpdateForm(video => video.filter(item => item.id !== video_id))
            }

        } catch (e) {
            setReceivedError(e)
        }
    }

    const deleteVideo = async (type, video_id) => {
        try {
            const data = await request(`/video/remove/${video_id}/${type}`, 'GET', null, {
                'x-access-token': token
            })
            // console.log('error ', error);
            if (data.changed === 1) {
                // setVideo(video => video.filter(item => item.id !== video_id))

                setContent(
                    content => content.map(
                        item => {
                            let new_videos = item['video_' + type].filter(video_item => video_item.id !== video_id).map((video_item, index) => {
                                video_item.index = index
                                return video_item
                            })
                            item['video_' + type] = new_videos
                            return item;
                        }
                    )
                )
            }
        } catch (e) {
            setReceivedError(e)
        }
    }

    const actionVideoFile = async (action, video_id, type, file, video_item_default) => {
        try {
            let data
            if (action === 'upload') {
                const form_data = new FormData()
                form_data.append('video', file)
                form_data.append('video_name', file.name)
                data = await request(`/video/upload/${video_id}/${type}`, 'POST', form_data, {
                    'x-access-token': token,
                    'Content-Type': 'multipart/form-data'
                }, true)
            } else {
                data = await request(`/video/file_remove/${video_id}/${file}/${type}`, 'GET', null, {
                    'x-access-token': token
                })
            }

            console.log('error ', error);
            if (data.video) {
                // actionVideoFileCallback()
                setContent(
                    content => content.map(
                        item => {
                            if (item['video_' + type] && item['video_' + type].length) {
                                item['video_' + type] = item['video_' + type].map(video_item => {
                                    if (video_item.id === video_id) {
                                        video_item = data.video[0]
                                        console.log(`${action} FILE, NEW CONTENT`);
                                        console.log(video_item);
                                    }
                                    return video_item
                                })
                            }

                            return item
                        }
                    )
                )
                if (type === '360') {
                    updateVideoHandler(type, video_item_default, 'file', data.video[0].file);
                }

            }
        } catch (e) {
            setReceivedError(e)
        }
    }

    const changeVideoIndex = async (upperId, lowerId, type) => {
        try {
            const data = await request(`/content/index_change/${upperId}/${lowerId}`, 'POST', {type: 'video_' + type}, {
                'x-access-token': token
            })
            console.log('error ', error);
            if (data.upper_changed === 1 && data.lower_changed === 1) {
                //if request success change name
                setContent(
                    content => content.map(
                        item => {
                            item['video_' + type] = item['video_' + type].map(video_item => {
                                if (video_item.id === upperId) {
                                    video_item.index = video_item.index - 1
                                }

                                if (video_item.id === lowerId) {
                                    video_item.index = video_item.index + 1
                                }

                                return video_item
                            })

                            return item
                        }
                    )
                )
            }

        } catch (e) {
            setReceivedError(e)
        }
    }

    return (
        <>
            {(receivedError) ? <Toaster errors_list={receivedError} error_state={setReceivedError}/> : ''}
            {loading && <Loader progress={progress} cancelState={abortUpload}/>}
            <h3 className="FormTitle">Experiences List</h3>
            <form className="FormBlock">
                {activeLanguage && activeLanguage.length && activeLanguage.filter(lang => lang.code === pageLanguage).map((lang, index) =>
                    <div key={lang.code} className="FormBlockElement">
                        <input type="text" className="FormInput BoxShadow"
                               placeholder={"Enter experience name " + lang.name} name={"name_" + lang.code}
                               onChange={changeHandler}
                               style={lang.code === "he" ? {direction: "rtl", textAlign: "right"} : null}/>
                        <button type="submit" className="ElementButton" disabled={loading}
                                onClick={(e) => {
                                    e.preventDefault();
                                    addScenario()
                                }}>
                            Add Experience
                        </button>
                    </div>
                )}

            </form>

            {(content && content.length)
                ?
                <div>
                    {content.filter(exp => exp.language === pageLanguage).sort((a, b) => a.index - b.index).map((item, index, array) =>
                        <div className="CardBlock BoxShadow mb-5" key={item.id}>
                            <Tabs className="CardBlockHeder" defaultActiveKey="video_flat">
                                <Tab
                                    tabClassName="CardBlockHederTabArrows"
                                    title={<div className="d-flex flex-column ">
                                        <IconContext.Provider value={{color: "#ccc"}}>
                                        {array[index - 1] &&
                                        <FaSortUp onClick={() => changeIndex(item.id, array[index - 1].id)}/>}
                                        {array[index + 1] &&
                                        <FaSortDown onClick={() => changeIndex(array[index + 1].id, item.id)}/>}
                                        </IconContext.Provider>
                                    </div>}
                                />

                                <Tab
                                    tabClassName="CardBlockHederTabName"
                                    title={<div className="align-content-start d-flex" onClick={() => {
                                        let default_data = {id: item.id}
                                        activeLanguage.map((lang_item, index) =>
                                            default_data['name_' + lang_item.code] = item['name_' + lang_item.code]
                                        )
                                        updateScenarioHandler(default_data)
                                    }}>

                                        {activeLanguage.length && activeLanguage
                                            .filter(lang => lang.code === pageLanguage)
                                            .map((lang_item, index) =>
                                                <div key={lang_item.name} >
                                                    <EditableSpan
                                                        key={index}
                                                        item={item}
                                                        updateHandler={updateScenarioHandler}
                                                        fieldName={'name_'}
                                                        langCode={lang_item.code}
                                                        textValue={item['name_' + lang_item.code]}
                                                    />
                                                </div>
                                            )}

                                    </div>}


                                />

                                {scenarioUpdateForm.some(scenarioUpdateForm => scenarioUpdateForm.id === item.id && scenarioUpdateForm.updated === 1)
                                &&
                                <Tab
                                    tabClassName="CardBlockHederTab"
                                    title={
                                        <img src="/img/save.png"
                                             onClick={() => changeScenario(item.id)}
                                             className="CardBlockHederImg"/>
                                    }
                                />
                                }


                                <Tab
                                    tabClassName="CardBlockHederTab"
                                    eventKey="video_flat"
                                    title={
                                        <div>
                                            <img src="/img/FlatVideo.png" className="TabBlockImg"/>
                                        </div>
                                    }
                                >
                                    <div className="mt-1 mb-2 ">
                                        <div className="TabInputBlock">
                                            <input
                                                className="FormInput BoxShadow"
                                                type="text"
                                                placeholder="Name"
                                                name="name"
                                                onChange={(e) => changeVideoHandler(e, "flat")}
                                            />
                                            <button
                                                className="ElementButton"
                                                type="submit"
                                                variant="success"
                                                disabled={loading}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    addVideo("flat", item.id);
                                                }}
                                            >
                                                Add Video
                                            </button>
                                        </div>
                                    </div>

                                    <table className="Table">
                                        <thead>
                                        <tr className="TableBlock">
                                            <th className="TableFirstLineBlock">Sort</th>
                                            <th className="TableFirstLineBlock">Name</th>
                                            <th className="TableFirstLineBlock">Video Flat</th>
                                            <th className="TableFirstLineBlock">Action</th>
                                        </tr>
                                        </thead>

                                        <tbody>
                                        {item.video_flat && item.video_flat.length ? (
                                            item.video_flat
                                                .sort((a, b) => a.index - b.index)
                                                .map((video_item, index, array) => (
                                                    <tr
                                                        key={video_item.id}
                                                        onClick={() => {
                                                            let default_data = {
                                                                id: video_item.id,
                                                                name: video_item.name,
                                                                file: video_item.file,
                                                            };
                                                            updateVideoHandler("flat", default_data);
                                                        }}
                                                    >
                                                        <td className="TableSecondLineBlock CenterContent d-flex">
                                                            <div className="d-inline-flex flex-column">
                                                            <IconContext.Provider value={{color: "white"}}>

                                                                {array[index - 1] && (
                                                                    <FaSortUp
                                                                        className="d-flex flex-column"
                                                                        onClick={() =>
                                                                            changeVideoIndex(
                                                                                video_item.id,
                                                                                array[index - 1].id,
                                                                                "flat"
                                                                            )
                                                                        }
                                                                    />
                                                                )}

                                                                {array[index + 1] && (
                                                                    <FaSortDown
                                                                        className="d-flex flex-column "
                                                                        onClick={() =>
                                                                            changeVideoIndex(
                                                                                array[index + 1].id,
                                                                                video_item.id,
                                                                                "flat"
                                                                            )
                                                                        }
                                                                    />
                                                                )}
                                                            </IconContext.Provider>
                                                            </div>
                                                        </td>

                                                        <td
                                                            className="TableSecondLineBlock TableSecondLineText TableSecondLineInput"
                                                            onKeyPress={(e) => {
                                                                if (e.which === 13 || e.keyCode === 13) {
                                                                    e.preventDefault();
                                                                }
                                                            }}
                                                            onInput={(e) => {
                                                                const val = e.currentTarget.textContent;
                                                                let data = video_item;
                                                                updateVideoHandler("flat", data, "name", val);
                                                                updateVideoHandler("flat", data, "updated", 1);
                                                            }}
                                                            contentEditable="true"
                                                        >
                                                            {video_item.name}
                                                        </td>
                                                        {video_item.file &&
                                                        video_item.file.length &&
                                                        video_item.file !== null ? (
                                                            <td className="TableSecondLineBlock TableSecondLineText">
                                                                {video_item.file}
                                                                <img
                                                                    src="/img/TrashB.png"
                                                                    onClick={() => {
                                                                        if (window.confirm("Delete this item?")) {
                                                                            actionVideoFile(
                                                                                "delete",
                                                                                video_item.id,
                                                                                "flat",
                                                                                video_item.file,
                                                                                video_item
                                                                            );
                                                                        }
                                                                    }}
                                                                    className="text-danger mx-4 TableSecondLineImg"
                                                                />
                                                                <a
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    href={`${
                                                                        process.env.REACT_APP_API
                                                                        }/static/video_flat/${
                                                                        video_item.id
                                                                        }/${encodeURIComponent(video_item.file)}`}
                                                                >
                                                                    <img
                                                                        src="/img/Edit.png"
                                                                        className="TableSecondLineImg"
                                                                    />
                                                                </a>
                                                            </td>
                                                        ) : (
                                                            <td className="TableSecondLineBlock ">
                                                                <label htmlFor="video_flat_input">
                                                                    <input
                                                                        type="file"
                                                                        id="video_flat_input"
                                                                        name="video_flat_file"
                                                                        style={{display: "none"}}
                                                                        onChange={(e) => {
                                                                            if (window.confirm("Upload this file?")) {
                                                                                actionVideoFile(
                                                                                    "upload",
                                                                                    video_item.id,
                                                                                    "flat",
                                                                                    e.target.files[0],
                                                                                    video_item
                                                                                );
                                                                            }
                                                                        }}
                                                                    />
                                                                    <img src="/img/Upload.png"
                                                                         className="TableSecondLineImg"
                                                                         alt="Upload new file"
                                                                    />
                                                                    <span className="TableSecondLineText mx-2">Select file..</span>
                                                                </label>
                                                            </td>
                                                        )}
                                                        <td className="TableSecondLineBlock CenterContent">
                                                            {videoFlatUpdateForm.some(
                                                                (array_item) =>
                                                                    array_item.id === video_item.id &&
                                                                    array_item.updated === 1
                                                            ) && (
                                                                <img
                                                                    src="/img/save_white.png"
                                                                    onClick={() => changeVideo("flat", video_item.id)}
                                                                    className="TableSecondLineImg mx-4"
                                                                />
                                                            )}
                                                            <img
                                                                src="/img/TrashB.png"
                                                                onClick={() => {
                                                                    if (window.confirm("Delete this item?")) {
                                                                        deleteVideo("flat", video_item.id);
                                                                    }
                                                                }}
                                                                className="text-danger mx-1 TableSecondLineImg"
                                                            />
                                                        </td>
                                                    </tr>
                                                ))
                                        ) : (
                                            <tr className="NoElementsText"><td>No Videos</td></tr>
                                        )}
                                        </tbody>
                                    </table>
                                </Tab>

                                <Tab
                                    tabClassName="CardBlockHederTab"
                                    eventKey="video_360"
                                    title={<img src="/img/360.png" className="TabBlockImg"/>}
                                    className="test"
                                >
                                    <div className="mt-1 mb-2 ">
                                        <div className="TabInputBlock">
                                            <input
                                                className="FormInput BoxShadow"
                                                type="text"
                                                placeholder="Name"
                                                name="name"
                                                onChange={(e) => changeVideoHandler(e, "360")}
                                            />
                                            <button
                                                className="ElementButton"
                                                type="submit"
                                                // variant="success"
                                                disabled={loading}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    addVideo("360", item.id);
                                                }}
                                            >
                                                Add Video
                                            </button>
                                        </div>
                                    </div>

                                    <table className="Table">
                                        <thead>
                                        <tr className="TableBlock">
                                            <th className="TableFirstLineBlock">Sort</th>
                                            <th className="TableFirstLineBlock">Name</th>
                                            <th className="TableFirstLineBlock">Video 360</th>
                                            <th className="TableFirstLineBlock">Type</th>
                                            <th className="TableFirstLineBlock">Mode</th>
                                            <th className="TableFirstLineBlock">Split</th>
                                            <th className="TableFirstLineBlock">Action</th>
                                        </tr>
                                        </thead>

                                        <tbody>
                                        {item.video_360 && item.video_360.length ? (
                                            item.video_360
                                                .sort((a, b) => a.index - b.index)
                                                .map((video_item, index, array) => (
                                                    <tr
                                                        key={video_item.id}
                                                        onClick={() => {
                                                            let default_data = {
                                                                id: video_item.id,
                                                                name: video_item.name,
                                                                type: video_item.type,
                                                                mode: video_item.mode,
                                                                split: video_item.split,
                                                                file: video_item.file,
                                                            };
                                                            updateVideoHandler("360", default_data);
                                                        }}
                                                    >
                                                        <td className="TableSecondLineBlock CenterContent d-flex">
                                                            <div className="d-inline-flex flex-column">
                                                            <IconContext.Provider value={{color: "white"}}>

                                                                {array[index - 1] && (
                                                                    <FaSortUp
                                                                        className="d-flex flex-column "
                                                                        onClick={() =>
                                                                            changeVideoIndex(
                                                                                video_item.id,
                                                                                array[index - 1].id,
                                                                                "360"
                                                                            )
                                                                        }
                                                                    />
                                                                )}

                                                                {array[index + 1] && (
                                                                    <FaSortDown
                                                                        className="d-flex flex-column "
                                                                        onClick={() =>
                                                                            changeVideoIndex(
                                                                                array[index + 1].id,
                                                                                video_item.id,
                                                                                "360"
                                                                            )
                                                                        }
                                                                    />
                                                                )}
                                                            </IconContext.Provider>
                                                            </div>
                                                        </td>

                                                        <td
                                                            className="TableSecondLineBlock TableSecondLineText"
                                                            onKeyPress={(e) => {
                                                                if (e.which === 13 || e.keyCode === 13) {
                                                                    e.preventDefault();
                                                                }
                                                            }}
                                                            onInput={(e) => {
                                                                const val = e.currentTarget.textContent;
                                                                let data = video_item;
                                                                updateVideoHandler("360", data, "name", val);
                                                                updateVideoHandler("360", data, "updated", 1);
                                                            }}
                                                            contentEditable="true"
                                                        >
                                                            {video_item.name}
                                                        </td>

                                                        {video_item.file &&
                                                        video_item.file.length &&
                                                        video_item.file !== null ? (
                                                            <td className="TableSecondLineBlock TableSecondLineText">
                                                                {video_item.file}
                                                                <img
                                                                    src="/img/TrashB.png"
                                                                    onClick={() => {
                                                                        if (window.confirm("Delete this item?")) {
                                                                            actionVideoFile(
                                                                                "delete",
                                                                                video_item.id,
                                                                                "360",
                                                                                video_item.file,
                                                                                video_item
                                                                            );
                                                                        }
                                                                    }}
                                                                    className="text-danger mx-4 TableSecondLineImg"
                                                                />
                                                                <a
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    href={`${
                                                                        process.env.REACT_APP_API
                                                                        }/static/video_360/${
                                                                        video_item.id
                                                                        }/${encodeURIComponent(video_item.file)}`}
                                                                >
                                                                    <img
                                                                        src="/img/Edit.png"
                                                                        className="TableSecondLineImg"
                                                                    />
                                                                </a>
                                                            </td>
                                                        ) : (
                                                            <td className="TableSecondLineBlock TableSecondLineText">
                                                                <label
                                                                    className="Video360Block"
                                                                    htmlFor="video_360_input"
                                                                >
                                                                    <input
                                                                        type="file"
                                                                        id="video_360_input"
                                                                        name="video_360_file"
                                                                        style={{display: "none"}}
                                                                        onChange={(e) => {
                                                                            if (window.confirm("Upload this file?")) {
                                                                                actionVideoFile(
                                                                                    "upload",
                                                                                    video_item.id,
                                                                                    "360",
                                                                                    e.target.files[0],
                                                                                    video_item
                                                                                );
                                                                            }
                                                                        }}
                                                                    />
                                                                    <img
                                                                        src="/img/Upload.png"
                                                                        className="text-warning TableSecondLineImg"
                                                                        alt="Upload new file"
                                                                    />
                                                                    <span
                                                                        className="mx-2 FileUploadText">Select file..</span>
                                                                </label>
                                                            </td>
                                                        )}
                                                        <td className="TableSecondLineBlock">
                                                            <div>
                                                                <div
                                                                    className="TableSecondLineForm"
                                                                    //controlId="exampleForm.SelectCustom"
                                                                >
                                                                    <select
                                                                        className="TableFormBlock"
                                                                        //as="select"
                                                                        //custom
                                                                        onChange={(e) => {
                                                                            console.log(
                                                                                "log select was changed",
                                                                                e.target.value
                                                                            );
                                                                            let select = e.target.value;
                                                                            let data = video_item;
                                                                            updateVideoHandler("360", data, "type", select);
                                                                            updateVideoHandler("360", data, "updated", 1);
                                                                        }}
                                                                        style={{
                                                                            minWidth: "75px",
                                                                        }}
                                                                        defaultValue={video_item.type}
                                                                    >
                                                                        <option disabled> -- select an option --
                                                                        </option>
                                                                        <option>Flat</option>
                                                                        <option>Panorama</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="TableSecondLineBlock">
                                                            <div>
                                                                <div
                                                                    className="TableSecondLineForm"
                                                                    // controlId="exampleForm.SelectCustom"
                                                                >
                                                                    <select
                                                                        className="TableFormBlock"
                                                                        //as="select"
                                                                        //custom
                                                                        onChange={(e) => {
                                                                            let select = e.target.value;
                                                                            let data = video_item;
                                                                            updateVideoHandler("360", data, "mode", select);
                                                                            updateVideoHandler("360", data, "updated", 1);
                                                                        }}
                                                                        style={{
                                                                            minWidth: "75px",
                                                                        }}
                                                                        defaultValue={video_item.mode}
                                                                    >
                                                                        <option disabled> -- select an option --
                                                                        </option>
                                                                        <option>None</option>
                                                                        <option>Transparent</option>
                                                                        <option>Stereo</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="TableSecondLineBlock">
                                                            <div>
                                                                <div
                                                                    className="TableSecondLineForm"
                                                                    //controlId="exampleForm.SelectCustom"
                                                                >
                                                                    <select
                                                                        className="TableFormBlock"
                                                                        // as="select"
                                                                        // custom
                                                                        onChange={(e) => {
                                                                            let select = e.target.value;
                                                                            let data = video_item;
                                                                            updateVideoHandler(
                                                                                "360",
                                                                                data,
                                                                                "split",
                                                                                select
                                                                            );
                                                                            updateVideoHandler("360", data, "updated", 1);
                                                                        }}
                                                                        style={{
                                                                            minWidth: "75px",
                                                                        }}
                                                                        defaultValue={video_item.split}
                                                                    >
                                                                        <option disabled> -- select an option --
                                                                        </option>
                                                                        <option>None</option>
                                                                        <option>Vertical</option>
                                                                        <option>Horizontal</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="TableSecondLineBlock CenterContent ">
                                                            {videoUpdateForm.some(
                                                                (array_item) =>
                                                                    array_item.id === video_item.id &&
                                                                    array_item.updated === 1
                                                            ) && (
                                                                <img
                                                                    src="/img/save_white.png"
                                                                    onClick={() => changeVideo("360", video_item.id)}
                                                                    className="text-warning mx-3 TableSecondLineImg"
                                                                />
                                                            )}
                                                            <img
                                                                src="/img/TrashB.png"
                                                                onClick={() => {
                                                                    if (window.confirm("Delete this item?")) {
                                                                        deleteVideo("360", video_item.id);
                                                                    }
                                                                }}
                                                                className="text-danger mx-1 TableSecondLineImg"
                                                            />
                                                        </td>
                                                    </tr>
                                                ))
                                        ) : (
                                            <tr className="NoElementsText">No Videos</tr>
                                        )}
                                        </tbody>
                                    </table>
                                </Tab>

                                <Tab
                                    tabClassName="CardBlockHederTab"
                                    eventKey="quiz"
                                    title={
                                        <div>
                                            <img src="/img/Quiz.png" className="TabBlockImg"/>
                                        </div>
                                    }
                                >
                                    <div className="mt-3 mb-2 ">
                                        <div className="TabInputBlock">
                                            <input
                                                className="FormInput BoxShadow"
                                                type="text"
                                                name="quiz_name"
                                                placeholder="Name"
                                                onChange={(e) => setQuizName(e.target.value)}
                                            />
                                            <button
                                                className="ElementButton"
                                                type="submit"
                                                variant="success"
                                                disabled={loading}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    addQuiz(item.id);

                                                }}
                                            >
                                                Add Quiz
                                            </button>
                                        </div>
                                    </div>

                                    <table className="Table">
                                        <thead>
                                        <tr className="TableBlock">
                                            <th className="TableFirstLineBlock">Sort</th>
                                            <th className="TableFirstLineBlock">Name</th>
                                            <th className="TableFirstLineBlock">Actions</th>
                                        </tr>
                                        </thead>

                                        <tbody>
                                        {item.quiz && item.quiz.length ? (
                                            item.quiz
                                                .sort((a, b) => a.index - b.index)
                                                .map((quiz_item, index, array) => (
                                                    <tr
                                                        key={quiz_item.id}
                                                        onClick={(e) => {
                                                            updateQuizHandler({
                                                                id: quiz_item.id,
                                                                name: quiz_item.name,
                                                            });
                                                        }}
                                                    >
                                                        <td className="TableSecondLineBlock CenterContent d-flex">
                                                            <div className="d-inline-flex flex-column">
                                                            <IconContext.Provider value={{color: "white"}}>

                                                                {array[index - 1] && (
                                                                    <FaSortUp
                                                                        className="align-self-center "
                                                                        onClick={() =>
                                                                            changeQuizIndex(quiz_item.id, array[index - 1].id)
                                                                        }
                                                                    />
                                                                )}

                                                                {array[index + 1] && (
                                                                    <FaSortDown
                                                                        className=""
                                                                        onClick={() =>
                                                                            changeQuizIndex(array[index + 1].id, quiz_item.id)
                                                                        }
                                                                    />
                                                                )}
                                                            </IconContext.Provider>
                                                            </div>
                                                        </td>
                                                        <td key={quiz_item.id} className="TableSecondLineBlock TableSecondLineText"

                                                              onKeyPress={(e) => {
                                                                  if (e.which === 13 || e.keyCode === 13) {
                                                                      e.preventDefault();
                                                                  }
                                                              }}
                                                              onInput={(e) => {
                                                                  const val = e.currentTarget.textContent;
                                                                  let data = quiz_item;
                                                                  updateQuizHandler(data, "name", val);
                                                                  updateQuizHandler(data, "updated", 1);
                                                              }}
                                                              contentEditable="true"
                                                          >
                                                            {quiz_item.name}

                                                        </td>
                                                        <td className="TableSecondLineBlock">
                                                            {quizUpdateForm.some(
                                                                (quizUpdateForm) =>
                                                                    quizUpdateForm.id === quiz_item.id &&
                                                                    quizUpdateForm.updated === 1
                                                            ) && (
                                                                <img
                                                                    src="/img/save_white.png"
                                                                    onClick={() => changeQuiz(quiz_item.id)}
                                                                    className="TableSecondLineImg"
                                                                />
                                                            )}

                                                            <Link to={`/quiz/${quiz_item.id}`}
                                                                  className="TableSecondLineImg mx-4">
                                                                <img src="/img/Edit.png" className="TableSecondLineImg"/>
                                                            </Link>

                                                            <img
                                                                src="/img/TrashB.png"
                                                                className="TableSecondLineImg"
                                                                onClick={() => {
                                                                    if (window.confirm("Delete this item?")) {
                                                                        deleteQuiz(quiz_item.id);
                                                                    }
                                                                }}
                                                            />


                                                        </td>
                                                    </tr>
                                                ))
                                        ) : (
                                           <tr><td><span className="NoElementsText">No Quizzes</span></td></tr>
                                        )}

                                </tbody>
                            </table>
                                </Tab>

                                <Tab
                                    tabClassName="CardBlockHederTabTresh CenterContent"
                                    title={
                                        //<span className="small">{item.id} </span>
                                        <img src="/img/Trash.png"  className="CardBlockHederImgSmall" onClick={() => {
                                            if (window.confirm('Delete this item?')) {
                                                deleteScenario(item.id)
                                            }
                                        }}/>
                                    }
                                />
                            </Tabs>


                        </div>
                    )

                    }
                </div>
                : <span className="NoElementsText">No Data</span>}


        </>
    )
}

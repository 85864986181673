import React from 'react'
import Spinner from 'react-bootstrap/Spinner'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Button from 'react-bootstrap/Button';
import styles from './loader.module.css';

const mystyle = {
  position: 'absolute',
  top: '50%',
    left: '50%',
    right: '0'
};

const mystyle_loader = {
  position: 'absolute',
  width: '20%',
  top: '60%',
    left: '40%',
    right: '0'
};

const mystyle_loader_cancel = {
  position: 'absolute',
  width: '20%',
  top: '80%',
    left: '40%',
    right: '0'
};


export const Loader = ({ progress, cancelState }) => {
  if (progress) {

    // console.log('progress', Math.floor( (100 * progress.progress) / progress.total ));
  }
  return(
    <div className={styles.loader}>
    <Spinner animation="border" role="status" variant="warning" size="lg" style={mystyle} >
      <span className="sr-only">Loading...</span>
    </Spinner>
    {progress && <ProgressBar label="Loading" animated now={Math.floor( (100 * progress.progress) / progress.total )}  style={mystyle_loader} />}
      {progress &&  <Button variant="danger" style={mystyle_loader_cancel}
        onClick={(e)=>{e.preventDefault(); cancelState(); console.log('cancel uploaded');} }>
          Cancel upload
        </Button>}
    </div>
  )
}

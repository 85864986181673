import React, {useContext,useEffect} from 'react'
import {useHistory, useLocation } from 'react-router-dom'
import {AuthContext} from '../context/AuthContext'
import {LanguagesContext} from '../context/LanguagesContext'
import { Navbar, Nav} from 'react-bootstrap';
import {DropDown} from "./Dropdown";

import './NavbarMenu.css';

export const NavbarMenu = () => {

  const history = useHistory()
    const location = useLocation();
    const auth = useContext(AuthContext)
    const { pageLanguage, activeLanguage,changePageLanguage } = useContext(LanguagesContext);

    const logoutHandler = event => {
    event.preventDefault()
    auth.logout()
    history.push('/')
  }

  useEffect(()=> {
     // console.log("NavBar init: " , pageLanguage, activeLanguage);

  });

  //console.log('role at nav', auth.role);
  //console.log('context', auth);


        return (
            <div className="header-wrapper">
                <header className="header">
                    <div className="logo-place">
                        <img className="header-logo" src="/img/OthrReality.png" />
                    </div>
                    <div className="navigation">
                        { auth.role === 'admin' ?
                            <button className="header-navigation-button">
                                <a href="/content" className={"header-navigation-link"
                                + (location.pathname.includes('/content') ? " currentSection" : "")}>
                                    Content
                                </a>
                            </button>
                            : null
                        }
                        { auth.role === 'admin' || auth.role === 'moderator' ?
                        <button className={"header-navigation-button"
                        + (location.pathname.includes('/client') ? " currentSection" : "")}>
                            <a href="/client" className="header-navigation-link">
                                {auth.role === 'admin' ? "Clients" : "Content"}
                            </a>
                        </button>
                            : null
                        }
                        <button className={"header-navigation-button"
                        + (location.pathname.includes('/statistic') ? " currentSection" : "")}>
                            <a href="/statistic" className="header-navigation-link">
                                Statistics
                            </a>
                        </button>

                        { auth.role === 'admin' ?
                            <button className={"header-navigation-button"
                            + (location.pathname.includes('/settings') ? " currentSection" : "")}>
                                <a href="/settings" className="header-navigation-link" >
                                    Settings
                                </a>
                            </button>
                            : null
                        }

                    </div>
                    <div className=" d-flex align-content-end">
                    {
                        location.pathname.includes('/content') ?
                            (<div>
                                <DropDown list={activeLanguage} current={pageLanguage} change={changePageLanguage}/>
                            </div>) : <div> </div>
                    }

                        <a href="#"  className={"ml-3 " + (location.pathname.includes('/content') ? "pt-3" : "pt-2")  }onClick={logoutHandler}>
                             <img className="logoutIcon" src="/img/logout.png" />
                        </a>


                    </div>
                </header>
            </div>
        );




}

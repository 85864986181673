import React from 'react'
import {BrowserRouter as Router} from 'react-router-dom'
import {useRoutes} from './routes'
import {useAuth} from './hooks/auth.hook'
import { useLanguages } from "./hooks/languages.hook";
import {AuthContext} from './context/AuthContext'
import { LanguagesContext } from "./context/LanguagesContext";
import {NavbarMenu} from './components/NavbarMenu'
import {Loader} from './components/Loader'

function App() {
    const {token, login, role, username, group, logout, tokenExp, ready} = useAuth();
    const {pageLanguage, changePageLanguage, activeLanguage, initList} = useLanguages();

    const token_exp_format = Math.floor(Date.now() / 1000)
    const isAuthenticated = !!token
    const routes = useRoutes(isAuthenticated, role)

    if (Number.isInteger(tokenExp) && tokenExp < token_exp_format) {
        //if token expired force logout
        console.log('its expired');
        // return logout()
    }

    console.error = (function () {
        var error = console.error

        return function (exception) {

            if ((exception + '').indexOf('Warning: A component is `contentEditable`') !== 0) {
                error.apply(console, arguments)
            }
        }
    })()

    if (!ready) {
        return <Loader/>
    }

    return (
        <AuthContext.Provider value={{
            token, tokenExp, role, username, group, login, logout, isAuthenticated
        }}>
            <LanguagesContext.Provider
                value={{
                    pageLanguage,
                    changePageLanguage,
                    activeLanguage,
                    initList
                }}>
                <Router>
                    {isAuthenticated && (role === 'admin' || role === 'moderator' || role === 'analytic') &&
                    <NavbarMenu/>}
                    <div className="container">
                        {routes}
                    </div>
                </Router>
            </LanguagesContext.Provider>
        </AuthContext.Provider>
    )
}

export default App

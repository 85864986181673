import React, {useCallback, useContext, useEffect, useState} from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import {useHttp} from '../../hooks/http.hook'
import {AuthContext} from '../../context/AuthContext'
import {Loader} from '../../components/Loader'
import {Toaster} from '../../components/Toaster'

import { Container, Dropdown, DropdownButton, ListGroup, Button, Form, Row, Col } from 'react-bootstrap';
import StatisticsBlock from "../../components/StatisticsBlock";

export const StatisticPage = () => {
    const {token, role} = useContext(AuthContext)
    const {request, loading, error} = useHttp()
    const [receivedError, setReceivedError] = useState(error)

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [objectSearch, setObjectSearch] = useState({name: "All", type: "all"});
    const [searchData, setSearchData] = useState({age_search_min: 0, age_search_max: 80})
    const [statisticCollection, setStatisticCollection] = useState(null);

    const [groups, setGroups] = useState([])
    const fetchGroups = useCallback(async () => {
        let path
        if (role === 'admin') {
            path = '/groups'
        } else if (role === 'moderator' || role === 'analytic') {
            path = '/moderator/groups'
        }
        try {
            const fetched = await request(path, 'GET', null, {
                'x-access-token': token
            })
            if (fetched.groups) {
                console.log("clients:", fetched.groups);
                setGroups(fetched.groups)
            }

        } catch (e) {
            setReceivedError(e)
        }
    }, [token, request, role])

    useEffect(() => {
        fetchGroups()
    }, [fetchGroups])

    const [content, setContent] = useState([])
    const [extraContent, setExtraContent] = useState([])
    const fetchContent = useCallback(async () => {
        try {
            const fetched = await request('/content?statistic=1', 'GET', null, {
                'x-access-token': token
            })
            if (fetched.content) {
                console.log("content:", fetched.content);
                setContent(fetched.content)
               // setExtraContent(fetched.content)
            }

        } catch (e) {
            setReceivedError(e)
        }
    }, [token, request])

    useEffect(() => {
        fetchContent()
    }, [fetchContent])


    const [time, setTime] = useState({min_date: null, max_date: null})
    const fetchTime = useCallback(async () => {


        try {
            const fetched = await request('/statistic/timestamp', 'GET', null, {
                'x-access-token': token
            });

            if (fetched.min_date && fetched.max_date) {
                let time = {min_date: fetched.min_date, max_date: fetched.max_date}
                setTime(time)

                // set calendar values on start
                setStartDate(new Date(parseInt(fetched.min_date)))
                setEndDate(new Date(parseInt(fetched.max_date)))

                console.log("fetched time:", startDate, endDate);
            }

        } catch (e) {
            setReceivedError(e)
        }
    }, [token, request])

    useEffect(() => {
        fetchTime()
    }, [fetchTime])


    // let searchDataTest = {platform:[]}
    const updateSearchData = (key, val) => {
        setSearchData(
            item => {
                item[key] = val
                return item
            }
        )
        //console.log('search upd:', key, val);
    }

    const getStatistic = async () => {

        try {
            if (endDate && startDate) {
                if (Date.parse(endDate) < Date.parse(startDate)) {
                    throw {errors: [{msg: 'Start date cant be after End date'}]}
                }

                console.log('date is good: ' + startDate + " / " + endDate);
                let stats = await request(`/statistic`, 'POST', {
                    ...objectSearch,
                    startDate: Date.parse(startDate),
                    endDate: Date.parse(new Date(endDate.setHours(23, 59, 59, 999)))
                }, {
                    'x-access-token': token
                })

                if (error)
                    console.log('error ', error);

                if (stats && stats.data) {

                    //setExtraContent(content)
                    setStatisticCollection(stats.data);
                    checkSearchParams(stats);
                    countStatistic(stats.data);
                }
            } else {
                throw {errors: [{msg: 'Date not set or set wrong'},startDate, endDate]}
            }
        } catch (e) {
            setReceivedError(e)
        }
    }

    const checkSearchParams = (stats) => {

        if (stats !== null) {
            if (stats.platform && stats.platform.length) {
                console.log('PLATFORM UPDATE TRIGGER');
                updateSearchData('platform', stats.platform)
                updateSearchData('platform_search', 'All')
            }
            updateSearchData('gender_search', 'All')
            updateSearchData('gender_search_title', 'All')
            updateSearchData('age_search', 'All')

            console.log('searchData:', searchData);
        }
    }

   /* const countStatistic = (logs) => {

        console.log('statistic counter for ', objectSearch, logs);

        let new_content = content   //show only related exp to current client
            .filter(exp => objectSearch.type === "all"
                || groups.find(g => g.id === objectSearch.id).experiences.includes(exp.id))
            .map(
                item => {
                    let scenario_data_started = logs.filter(scenario_item => {
                        return scenario_item.params.experienceId === item.id && scenario_item.event === 'ExperienceStart'
                    })
                    let scenario_data_finished = logs.filter(scenario_item => {
                        return scenario_item.params.experienceId === item.id && scenario_item.event === 'ExperienceEnd'
                    })

                    item.scenario_started = scenario_data_started.length
                    item.scenario_finished = scenario_data_finished.length

                    if (item.video_360 && item.video_360.length) {
                        item.video_360 = item.video_360.map(video_item => {
                            let video_data_started = logs.filter(video_item_data => {
                                return video_item_data.params.videoId === video_item.id && video_item_data.event === 'VideoStart' && video_item_data.params.type === "360"
                            })
                            let video_data_finished = logs.filter(video_item_data => {
                                return video_item_data.params.videoId === video_item.id && video_item_data.event === 'VideoEnd' && video_item_data.params.type === "360"
                            })

                            video_item.video_started = video_data_started.length
                            video_item.video_finished = video_data_finished.length
                            return video_item
                        })
                    }

                    if (item.video_flat && item.video_flat.length) {
                        item.video_flat = item.video_flat.map(video_item => {
                            let video_data_started = logs.filter(video_item_data => {
                                return video_item_data.params.videoId === video_item.id && video_item_data.event === 'VideoStart' && video_item_data.params.type === 'flat'
                            })
                            let video_data_finished = logs.filter(video_item_data => {
                                return video_item_data.params.videoId === video_item.id && video_item_data.event === 'VideoEnd' && video_item_data.params.type === 'flat'
                            })

                            video_item.video_started = video_data_started.length
                            video_item.video_finished = video_data_finished.length
                            return video_item
                        })
                    }

                    if (item.quiz && item.quiz.length) {
                        item.quiz = item.quiz.map(quiz_item => {
                            let quiz_data_viewed = logs.filter(quiz_item_data => {
                                // console.log("q param:",  quiz_item_data)
                                return quiz_item_data.params.experienceId === item.id && quiz_item_data.event === 'QuizViewed'  //quiz_item_data.params.quizId === quiz_item.id -- before quiz start to be 1 list
                            })
                            quiz_item.quiz_viewed = quiz_data_viewed.length

                            if (quiz_item.answers && quiz_item.answers.length) {
                                quiz_item.answers = quiz_item.answers.map(quiz_answer => {
                                    let quiz_data_answered = logs.filter(quiz_answer_data => {
                                        return quiz_answer_data.params.answerId === quiz_answer.id && quiz_answer_data.event === 'QuizAnswer'
                                    })
                                    quiz_answer.answered = quiz_data_answered.length

                                    return quiz_answer
                                })
                            }

                            return quiz_item
                        })
                    }

                    return item
                }
            )
        setExtraContent(new_content)
        console.log("new_content:", new_content)

    }*/

    const countStatistic = (logs) => {

        console.log('statistic counter for ', objectSearch, logs);

        let groupsData = groups
            .filter(g => objectSearch.type === "all"
                || g.id === objectSearch.id)
            .map(group => {

                let result = {
                    name: "",
                    progress: {started: 0, finished: 0},
                    gender: {male: 0, female: 0, other: 0, unknown: 0},
                    age: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //amount of exp start for each age interval, 1st is unknowns
                    logs: []
                };
                result.name = group.name;
                result.id = group.id;

                let currentGroupLogs = logs.filter(log => log.group === group.id && log.platform !== "WindowsEditor");
                console.log("currentGroupLogs",currentGroupLogs)

                content
                    .filter(exp => group.experiences.includes(exp.id))
                    .forEach(experience => {
                            console.log("checking exp:", group.id, experience);
                            let scenario_started = currentGroupLogs.filter(log => {
                                if (
                                    log.event === 'ExperienceStart'
                                    && log.params.experienceId === experience.id
                                ) {
                                    switch (log.gender) {
                                        case 0:
                                            result.gender.female++;
                                            break;
                                        case 1:
                                            result.gender.male++;
                                            break;
                                        case 2:
                                            result.gender.other++;
                                            break;
                                        case -1:
                                            result.gender.unknown++;
                                            break;
                                    }

                                    if (log.age === 0)
                                        result.age[0]++;
                                    else
                                        result.age[Math.floor(log.age / 10) ]++;

                                    return true;
                                }
                                else
                                    return false;
                            }).length;

                            let scenario_finished = currentGroupLogs.filter(log =>
                                log.event === 'ExperienceEnd'
                                && log.params.experienceId === experience.id
                            ).length;

                            result.progress = {
                                started: result.progress.started + scenario_started,
                                finished: result.progress.finished + scenario_finished
                            };

                            currentGroupLogs.forEach(log => {
                                if (log.event === "QuizAnswer" && experience.id === log.params.experienceId) {

                                    let line = {
                                        date: new Date(parseInt(log.time,10)).toISOString(),
                                        platform: log.platform,
                                        gender: genderToLabel(log.gender),
                                        age: log.age,
                                        experienceId: log.params.experienceId,
                                        experienceName: log.params.experienceName,
                                        quizId: log.params.quizId,
                                        answers: log.params.answers
                                    };
                                    result.logs.push(line);
                                    console.log("pushing log")

                                }
                            });

                        }
                    )

                return result;
            });

        /* TODO
        let summ = {};
        if (objectSearch.type === "all")
            groupsData.forEach()
            */

        setExtraContent(groupsData)
        console.log("groupsData:", groupsData)

    };

    let genderToLabel = (id) => {

        switch (id)
        {
            case -1 : return "unknown";
            case 0 : return "female";
            case 1 : return "male";
            case 2 : return "other";
        }

    };

    const applyFilter = () => {

        console.log('APPLIED SEARCH', searchData);
        let data = statisticCollection

        if (searchData.age_search !== 'All') {
            data = data.filter(item => {
                return item.age >= searchData.age_search_min && item.age <= searchData.age_search_max
            })
        }

        if (searchData.gender_search !== 'All') {
            data = data.filter(item => {
                return item.gender >= parseInt(searchData.gender_search)
            })
        }

        if (searchData.platform_search !== 'All') {
            data = data.filter(item => {
                return item.platform >= searchData.platform_search
            })
        }

        console.log('filtered logs:', data);
        countStatistic(data)
        updateSearchData('filtered', 1)

    }


    return (
        <Container>
            {(receivedError) && console.log(receivedError) ?
                <Toaster errors_list={receivedError} error_state={setReceivedError}/> : ''}
            {loading && <Loader/>}

            <h3 className="FormTitle">Users activity</h3>
            {
                time.min_date && time.max_date &&
                <div>
                    <div className="d-flex FormText ">
                        <div>
                           From: <DatePicker
                                selected={startDate}
                                onChange={date => setStartDate(date)}
                                minDate={new Date(parseInt(time.min_date))}
                                maxDate={new Date(parseInt(time.max_date))}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                placeholderText="Select Start Date"
                                className="d-flex rounded border-white"
                                style={{position: 'auto'}}
                                closeOnScroll={true}
                            />
                        </div>
                        <div className="mx-2 ">
                          To: <DatePicker
                                selected={endDate}
                                onChange={date => setEndDate(date)}
                                minDate={new Date(parseInt(time.min_date))}
                                maxDate={new Date(parseInt(time.max_date))}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                placeholderText="Select End Date"
                                className="d-inline rounded border-white"
                                fixedHeight
                                closeOnScroll={true}
                            />

                        </div>
                    </div>
                    <div className="my-4">

                        <DropdownButton id="dropdown-basic-button" variant="info" title="Select Users"
                                        className="d-inline mx-1 ">
                            {role === 'admin'
                            &&
                            <Dropdown.Item
                                onClick={() => setObjectSearch({type: 'all', name: 'All'})}>All</Dropdown.Item>
                            }

                            {(groups && groups.length)
                                ?
                                groups.map((item, index) =>

                                    <Dropdown.Item
                                        onClick={() => setObjectSearch({type: 'company', name: item.name, id: item.id})}
                                        key={index}>
                                        <span className="FormTextDark">{item.name}</span>
                                    </Dropdown.Item>
                                )
                                : ''}

                        </DropdownButton>
                        <button id="dropdown-basic-button" onClick={getStatistic}
                                className="ElementButton d-inline mx-2">
                            Show {objectSearch && objectSearch.name && 'for ' + objectSearch.name}
                        </button>

                    </div>

                </div>
            }

            {/*
                statisticCollection &&
            <Form className="mt-3 mb-2 ">
                <Row className="d-flex align-items-center">
                    {(searchData.platform && searchData.platform.length)
                        ?
                        <Col>
                            <Form.Group>
                                <Form.Label>Platform</Form.Label>
                                <Form.Control as="select" custom
                                              onChange={(e) => {
                                                  let select = e.target.value;
                                                  updateSearchData('platform_search', e.target.value)
                                              }
                                              }
                                              className="" style={{
                                    minWidth: '75px'
                                }}>
                                    <option value='All'>All</option>
                                    {searchData.platform.map((item, index) =>
                                        <option key={index} value={item}>{item}</option>
                                    )}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        : ''
                    }
                    <Col>
                        <Form.Group>
                            <Form.Label>Gender</Form.Label>
                            <Form.Control as="select" custom
                                          onChange={(e) => {
                                              let select = e.target.value, title;
                                              updateSearchData('gender_search', select)
                                              switch (select) {
                                                  case 'All':
                                                      title = 'All'
                                                      break;
                                                  case '0':
                                                      title = 'Female'
                                                      break;
                                                  case '1':
                                                      title = 'Male'
                                                      break;
                                                  default:
                                                      title = 'All'
                                              }
                                              updateSearchData('gender_search_title', title)
                                          }
                                          }
                                          className="" style={{
                                minWidth: '75px'
                            }}>
                                <option value='All'>All</option>
                                <option value='1'>Male</option>
                                <option value='0'>Female</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>

                    <Col>
                        <Form.Group>
                            <Form.Label>Age</Form.Label>
                            <Form.Control as="select" custom
                                          onChange={(e) => {
                                              let select = e.target.value;
                                              let minAge, maxAge
                                              switch (select) {
                                                  case 'All':
                                                      minAge = 0
                                                      maxAge = 80
                                                      break;
                                                  case '0':
                                                      minAge = 1
                                                      maxAge = 10
                                                      break;
                                                  case '1':
                                                      minAge = 11
                                                      maxAge = 20
                                                      break;
                                                  case '2':
                                                      minAge = 21
                                                      maxAge = 30
                                                      break;
                                                  case '3':
                                                      minAge = 31
                                                      maxAge = 40
                                                      break;
                                                  case '4':
                                                      minAge = 41
                                                      maxAge = 50
                                                      break;
                                                  case '5':
                                                      minAge = 51
                                                      maxAge = 60
                                                      break;
                                                  case '6':
                                                      minAge = 61
                                                      maxAge = 70
                                                      break;
                                                  case '7':
                                                      minAge = 71
                                                      maxAge = 80
                                                      break;
                                                  default:
                                                      minAge = 0
                                                      maxAge = 80
                                              }
                                              // updateSearchData('gender_search', parseInt(e.target.value))
                                              updateSearchData('age_search_min', minAge)
                                              updateSearchData('age_search_max', maxAge)
                                              updateSearchData('age_search', select)
                                          }
                                          }
                                          className="" style={{
                                minWidth: '75px'
                            }}>
                                <option value='All'>All</option>
                                <option value='0'>0-10</option>
                                <option value='1'>10-20</option>
                                <option value='2'>20-30</option>
                                <option value='3'>30-40</option>
                                <option value='4'>40-50</option>
                                <option value='5'>50-60</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>

                    <Col className="align-self-end">
                        <Form.Group>
                            <Button variant="primary" disabled={loading}
                                    onClick={() => applyFilter()}>
                                Filter
                            </Button>
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
            */}

            {(searchData.filtered && searchData.filtered === 1)
                ?
                <span>Filters Applied for Minimum age {searchData.age_search_min}, Maximum age {searchData.age_search_max}, Gender: {searchData.gender_search_title}, Platform {searchData.platform_search}</span>
                : ''
            }
            {(objectSearch && extraContent && statisticCollection)
                ?
                <ListGroup className="my-2">
                    {extraContent.length ?

                        extraContent.map((groupData, index) =>
                            /*
                            <ListGroup.Item key={item.id}>
                                <div className="d-flex">
                                    <h5 className=" d-inline w-25">{item.name_en}</h5>
                                    <span className="mx-1">Started: {item.scenario_started}</span>
                                    <span className="mx-1">Finished: {item.scenario_finished}</span>
                                    <span className="mx-1">{item.public ? '[public]' : ''}</span>
                                </div>
                                {(item.video_360 && item.video_360.length)
                                    ?
                                    <div className="my-1">

                                        <h6>Video 360</h6>

                                        {item.video_360.map((video_item, index) =>
                                            <div key={video_item.id} className="d-flex">
                                                <span className="d-inline w-25">{video_item.name}</span>
                                                <span className="mx-1">Started: {video_item.video_started}</span>
                                                <span className="mx-1">Finished: {video_item.video_finished}</span>
                                            </div>
                                        )}
                                    </div>
                                    : ''
                                }

                                {(item.video_flat && item.video_flat.length)
                                    ?
                                    <div className="my-1">

                                        <h6>Video Flat</h6>

                                        {item.video_flat.map((video_item, index) =>
                                            <div key={video_item.id} className="d-flex">
                                                <span className="d-inline w-25">{video_item.name}</span>
                                                <span className="mx-1">Started: {video_item.video_started}</span>
                                                <span className="mx-1">Finished: {video_item.video_finished}</span>
                                            </div>
                                        )}
                                    </div>
                                    : ''
                                }

                                {(item.quiz && item.quiz.length)
                                    ?
                                    <div className="my-1">
                                        <h6>Quizzes</h6>
                                        {item.quiz.map((quiz_item, index) =>
                                            <div key={quiz_item.id} className="d-flex flex-column">
                                                <div className="d-flex">
                                                    <span className="d-inline w-25">{quiz_item.name_en}</span>
                                                    <span className="ml-1">Viewed: {quiz_item.quiz_viewed}</span>
                                                </div>
                                                {(quiz_item.answers && quiz_item.answers.length)
                                                    ?
                                                    <div className="mx-4">
                                                        {quiz_item.answers.map((quiz_answer, index) =>
                                                            <div key={quiz_answer.id} className="d-flex">
                                                                <span
                                                                    className="d-inline w-25"> - {quiz_answer.name_en}</span>
                                                                <span
                                                                    className="">Answered: {quiz_answer.answered}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    : ''
                                                }
                                            </div>
                                        )}
                                    </div>
                                    : ''
                                }
                            </ListGroup.Item>
                            */ {

                               // console.log("data:", groupData);
                              return   <StatisticsBlock key={groupData.id} data={groupData}/>;
                            }
                        )

                        : <span className="my-2 NoElementsText">No content</span>}
                </ListGroup>
                : <div className="d-block mx-auto my-1">
                    <span className="my-2 NoElementsText">No Statistic</span>
                  </div>
            }

        </Container>
    )
}

import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useParams, useLocation, useHistory} from 'react-router-dom';  //location in use to pass custom quiz language from route Link
import {useHttp} from '../../hooks/http.hook'
import {AuthContext} from '../../context/AuthContext'
import { LanguagesContext } from "../../context/LanguagesContext";
import {Loader} from '../../components/Loader'

import {Toaster} from '../../components/Toaster'

import { Button, Form, Card, Badge, Tab, Tabs} from 'react-bootstrap';
import { FaTrashAlt, FaSave } from 'react-icons/fa';
import EditableSpan from "../../components/EditableSpan";

export const QuizDetailPage = () => {
    const {token} = useContext(AuthContext)
    const {pageLanguage, activeLanguage} = useContext(LanguagesContext);
    const {request, loading, error} = useHttp()
    const [receivedError, setReceivedError] = useState(error)

    const quizId = useParams().id

    const [quiz, setQuiz] = useState([])

    const [questionUpdateForm, setQuestionUpdateForm] = useState([])
    const [answerUpdateForm, setAnswerUpdateForm] = useState([])

    let currentPageLanguage = pageLanguage;

    const [quizName, setQuizName] = useState()
    const [quizVideos, setQuizVideos] = useState([]);

    const fetchQuizInfo = useCallback(async () => {
        console.log("start fetch");

        try {
            const fetched = await request(`/quiz/info/${quizId}`, 'GET', null, {
                'x-access-token': token
            });

            if (fetched.quiz) {
                setQuizName(fetched.quiz.name);
                setQuizVideos(fetched.quiz.videos);
            }
        } catch (e) {
            setReceivedError(e)
        }
    }, [token, request, quizId])

    const location = useLocation();

    useEffect(() => {
        fetchQuizInfo();
        if (location.state && location.state.customLanguage)
            currentPageLanguage = location.state.customLanguage;

    }, [fetchQuizInfo])

    //const [activeLanguage, setActiveLanguage] = useState([])
    const [formQuestion, setFormQuestion] = useState({name_en: ''})
    const changeHandler = event => {
        setFormQuestion({...formQuestion, [event.target.name]: event.target.value})
    }
    const [formAnswer, setFormAnswer] = useState({name_en: ''})
    const changeAnswerHandler = event => {
        setFormAnswer({...formAnswer, [event.target.name]: event.target.value})
    }

    /* const fetchActiveLanguage = useCallback(async () => {
       try {
         const fetched = await request('/settings/language/active', 'GET', null, {
           'x-access-token': token
         })

         if (fetched.languages) {
           setActiveLanguage(fetched.languages)
         }
         // console.log(settingsLanguage);

       } catch (e) {setReceivedError(e)}
     }, [token, request])

     useEffect(() => {
       fetchActiveLanguage()
     }, [fetchActiveLanguage])

   */

    const fetchQuiz = useCallback(async () => {
        try {
            const fetched = await request(`/quiz/${quizId}`, 'GET', null, {
                'x-access-token': token
            })

            if (fetched.quiz) {
                console.log("fetched.quiz:", fetched.quiz);

                fetched.quiz.map((q)=>{
                    for (const [key, value] of Object.entries(q)) {
                        if (key.includes("name_"))
                            q[key] = decodeURI(value);
                    }

                    q.answers.map((a)=> {
                        for (const [key2, value2] of Object.entries(a)) {
                            if (key2.includes("name_"))
                                a[key2] = decodeURI(value2);
                        }
                        return a;
                    });

                    return q;
                });

                setQuiz(fetched.quiz)
            }

        } catch (e) {
            setReceivedError(e)
        }
    }, [token, request, quizId])

    useEffect(() => {
        fetchQuiz()
    }, [fetchQuiz])

    const updateQuestionHandlerDefault = (default_data) => {
        console.log("updateQuestionHandler:", default_data);
        if (default_data.id !== questionUpdateForm.id) {
            setQuestionUpdateForm(default_data)
        }

    }

    const updateQuestionHandler = (data, key, val) => {
        console.log();
        const temp = questionUpdateForm
        if (temp.some(item => item.id === data.id)) {
            //console.log("Object found inside the array.");
            setQuestionUpdateForm(
                questionUpdateForm => questionUpdateForm.map(
                    item => {
                        if (item.id === data.id) {
                            item[key] = val
                        }
                        return item
                    }
                )
            )

        } else {
            //  console.log("Object not found.");
            setQuestionUpdateForm([...questionUpdateForm, data])
        }

    }

    const updateAnswerHandler = (data, key, val) => {
        const temp = answerUpdateForm
        if (temp.some(item => item.id === data.id)) {
            //console.log("Object found inside the array.");
            setAnswerUpdateForm(
                answerUpdateForm => answerUpdateForm.map(
                    item => {
                        if (item.id === data.id) {
                            item[key] = val
                        }
                        return item
                    }
                )
            )

        } else {
            //  console.log("Object not found.");
            setAnswerUpdateForm([...answerUpdateForm, data])
        }

    }

    const addQuestion = async () => {
        try {
            const data = await request(`/quiz/question_add/${quizId}`, 'POST', formQuestion, {
                'x-access-token': token
            })
            console.log('error ', error);
            if (data.question) {
                setQuiz(quiz => [...quiz, data.question[0]])
            }

            // setUsers(users => [...users, test_group] )

            // history.push(`/detail/${data.link._id}`)
        } catch (e) {
            setReceivedError(e)
        }
    }

    const changeQuestion = async (question_id) => {
        try {
            let data_form = questionUpdateForm.filter(item => {
                return item.id === question_id
            })

            const data = await request(`/quiz/question_update/${question_id}`, 'POST', data_form[0], {
                'x-access-token': token
            })
            if (error)
                console.log('error ', error);
            if (data.changed === 1) {
                //if request success change name
                //console.log('name should be changed');
                setQuiz(
                    quiz => quiz.map(
                        item => {
                            if (item.id === question_id) {
                                activeLanguage.map((lang_item, index) =>
                                    item['name_' + lang_item.code] = questionUpdateForm['name_' + lang_item.code]
                                )
                            }
                            return item
                        }
                    )
                )

                setQuestionUpdateForm([]);
            }

        } catch (e) {
            setReceivedError(e)
        }
    }

    const deleteQuestion = async (question_id) => {
        try {
            const data = await request(`/quiz/question_delete/${question_id}`, 'GET', null, {
                'x-access-token': token
            })
            console.log('error ', error);
            if (data.changed === 1) {
                setQuiz(quiz => quiz.filter(item => item.id !== question_id))
            }
        } catch (e) {
            setReceivedError(e)
        }
    }

    const addAnswer = async (question_id) => {
        try {
            const data = await request(`/quiz/answer_add/${question_id}`, 'POST', formAnswer, {
                'x-access-token': token
            })

            if (error)
            console.log('error ', error);

            if (data.answer) {

                data.answer.map((a)=>{
                    for (const [key, value] of Object.entries(a)) {
                        if (key.includes("name_"))
                            a[key] = decodeURI(value);
                    }

                    return a;
                });

                setQuiz(
                    quiz => quiz.map(
                        item => {
                            if (item.id === question_id)
                                if (item.answers && item.answers.length) {
                                    item.answers = [...item.answers, data.answer[0]]
                                } else {
                                    item.answers = [ data.answer[0]]
                                }

                            return item;
                        }
                    )
                )
            }
        } catch (e) {
            setReceivedError(e)
        }
    }

    const changeAnswer = async (answer_id) => {
        try {
            let data_form = answerUpdateForm.filter(item => {
                return item.id === answer_id
            })
            const data = await request(`/quiz/answer_update/${answer_id}`, 'POST', data_form[0], {
                'x-access-token': token
            })
            console.log('error ', error);
            if (data.changed === 1) {
                //if request success change name
                console.log('name should be changed');
                setQuiz(
                    quiz => quiz.map(
                        item => {
                            item.answers.map(answer_item => {
                                if (answer_item.id === answer_id) {
                                    activeLanguage.map((lang_item, index) =>
                                        answer_item['name_' + lang_item.code] = data_form[0]['name_' + lang_item.code]
                                    )
                                }
                                return answer_item
                            })

                            return item
                        }
                    )
                )
            }
            setAnswerUpdateForm(answer => answer.filter(item => item.id !== answer_id))
        } catch (e) {
            setReceivedError(e)
        }
    }

    const deleteAnswer = async (answer_id) => {
        try {
            const data = await request(`/quiz/answer_delete/${answer_id}`, 'GET', null, {
                'x-access-token': token
            })
            console.log('error ', error);
            if (data.changed === 1) {
                setQuiz(
                    quiz => quiz.map(
                        item => {
                            let new_answers = item.answers.filter(answer_item => answer_item.id !== answer_id)
                            item.answers = new_answers
                            return item;
                        }
                    )
                )
            }
        } catch (e) {
            setReceivedError(e)
        }
    }


    // new for VIDEO
    //const [content, setContent] = useState([])
    //const [videoUpdateForm, setVideoUpdateForm] = useState([])
    const [videoQuizUpdateForm, setVideoQuizUpdateForm] = useState([])

 /*   const updateVideoHandler = (type, data, key, val) => {

        if (videoQuizUpdateForm.some(item => item.id === data.id) && key) {

            console.log("Object found inside the array.");

            setVideoQuizUpdateForm(
                videoQuizUpdateForm => videoQuizUpdateForm.map(
                    item => {
                        if (item.id === data.id) {
                            item[key] = val
                        }
                        return item
                    }
                )
            )

        } else {
            console.log("Object not found.");

            setVideoQuizUpdateForm([...videoQuizUpdateForm, data])

        }
    }*/

    const actionVideoFile = useCallback( async (action, video_id, type, file, video_item_default) => {
        try {
            let data
            if (!video_id) {
                console.log("error of video_id:", video_id, video_item_default);
                return;
            }

            console.log(" video_id", video_id,video_item_default);

            if (action === 'upload') {

                const form_data = new FormData()
                form_data.append('video', file)
                form_data.append('video_name', file.name)

                data = await request(`/video/upload/${video_id}/${type}`, 'POST', form_data, {
                    'x-access-token': token,
                    'Content-Type': 'multipart/form-data'
                }, true)

                setQuizVideos(videos => { return videos.map(v => { v.file = data.video[0].file; return v; })});

            } else {
                data = await request(`/video/file_remove/${video_id}/${file}/${type}`, 'GET', null, {
                    'x-access-token': token
                })

                setQuizVideos(videos => { return videos.map(v => v.file == null) });
            }

            if (error)
               console.log('error ', error);


        } catch (e) {
            setReceivedError(e)
        }
    }, [quizVideos]);


    const deleteVideo = async (type, video_id) => {
        try {

            if (!video_id) {
                console.log("error of video_id:", video_id);
                return;
            }

            const data = await request(`/video/remove/${video_id}/${type}`, 'GET', null, {
                'x-access-token': token
            })
            // console.log('error ', error);
            if (data.changed === 1) {
                // setVideo(video => video.filter(item => item.id !== video_id))

               setQuizVideos([]);
            }
        } catch (e) {
            setReceivedError(e)
        }
    }

    const addVideo = async () => {
        try {
            let form = {};
            let video_type = "quiz";
            form.name = "some name";
            form.type = video_type;

            let data = await request(`/video/add/${quizId}`, 'POST', form, {
                'x-access-token': token
            });

            setQuizVideos([data.video])
        } catch (e) {
            setReceivedError(e)
        }
    }

    useEffect(() => {

    });

    const history = useHistory();

    return (
        <>
            {(receivedError) ? <Toaster errors_list={receivedError} error_state={setReceivedError}/> : ''}
            {loading && <Loader/>}

            <h3 className="FormTitle">
                <a href="#" onClick={() => {
                    history.goBack();
                }} className="FormTitle">{"< "}</a>
                Quiz setup ({currentPageLanguage})
                {": " + quizName}
            </h3>

            <table className="Table mb-5">
                <thead>
                <tr className="TableBlock">
                    <th className="TableFirstLineBlock">Video on start</th>
                    <th className="TableFirstLineBlock">Remove</th>
                </tr>
                </thead>

                <tbody>
                {quizVideos && quizVideos.length ? (
                    quizVideos
                       // .sort((a, b) => a.index - b.index)
                        .map((video_item, index, array) => (
                            <tr
                                key={video_item.id +1 }
                              /*  onClick={() => {

                                    let default_data = {
                                        id: video_item.id,
                                        name: video_item.name,
                                        file: video_item.file,
                                    };

                                    updateVideoHandler("quiz", default_data);
                                }}*/
                            >

                                {video_item.file && video_item.file !== null
                                    ? (
                                    <td className="TableSecondLineBlock TableSecondLineText">
                                        {video_item.file}
                                        <img
                                            src="/img/TrashB.png"
                                            onClick={() => {
                                                if (window.confirm("Delete this item?")) {
                                                    actionVideoFile(
                                                        "delete",
                                                        video_item.id,
                                                        "quiz",
                                                        video_item.file,
                                                        video_item
                                                    );
                                                }
                                            }}
                                            className="text-danger mx-4 TableSecondLineImg"
                                        />
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={`${
                                                process.env.REACT_APP_API
                                                }/static/video_quiz/${
                                                video_item.id
                                                }/${encodeURIComponent(video_item.file)}`}
                                        >
                                            <img
                                                src="/img/Edit.png"
                                                className="TableSecondLineImg"
                                            />
                                        </a>
                                    </td>
                                ) : (
                                    <td className="TableSecondLineBlock ">
                                        <label htmlFor="video_quiz_input">
                                            <input
                                                type="file"
                                                id="video_quiz_input"
                                                name="video_quiz_file"
                                                style={{display: "none"}}
                                                onChange={(e) => {
                                                    if (window.confirm("Upload this file?")) {
                                                        actionVideoFile(
                                                            "upload",
                                                            video_item.id,
                                                            "quiz",
                                                            e.target.files[0],
                                                            video_item
                                                        );
                                                    }
                                                }}
                                            />
                                            <img src="/img/Upload.png"
                                                 className="TableSecondLineImg"
                                                 alt="Upload new file"
                                            />
                                            <span className="TableSecondLineText mx-2">Select file..</span>
                                        </label>
                                    </td>
                                )}
                                <td className="TableSecondLineBlock CenterContent">
                                    <img
                                        src="/img/TrashB.png"
                                        onClick={() => {
                                            if (window.confirm("Delete this item?")) {
                                                deleteVideo("quiz", video_item.id);
                                            }
                                        }}
                                        className="text-danger mx-1 TableSecondLineImg"
                                    />
                                </td>
                            </tr>
                        ))
                ) : (
                    <tr className="NoElementsText">
                        <td><div>
                            <span className="mr-1">No Videos</span>
                            <a href="#" className="NoElementsText"
                               onClick={() => addVideo()}>
                                (+Add)
                            </a>
                        </div></td>
                    </tr>
                )}
                </tbody>
            </table>

            <div className="mb-5">
                {activeLanguage && activeLanguage.length && activeLanguage
                    .filter(lang => lang.code === currentPageLanguage)
                    .map((lang, index) =>
                        <form className="FormBlockElement" key={lang.code}>
                            <input type="text" className="FormInput" placeholder={"Enter new question in " + lang.name}
                                          name={"name_" + lang.code} onChange={changeHandler}
                                          style={lang.code === "he" ? {
                                              direction: "rtl",
                                              textAlign: "right"
                                          } : null}/>
                            <button type="submit" className="ButtonBlock mt-0 ml-3" variant="success" disabled={loading}
                                    onClick={addQuestion}>
                                Add Question
                            </button>
                        </form>
                    )}

            </div>




            {(quiz && quiz.length)
                ?
                quiz.map((question_item, index) =>
                    <div className="CardBlock BoxShadow mb-5" key={question_item.id}>
                        <div className="CardBlockHeder d-flex ">
                            <div className="CardBlockHederTabNameWide"
                                 onClick={() => {
                                     let default_data = {id: question_item.id}
                                     activeLanguage
                                         .map((lang_item, index) =>
                                             default_data['name_' + lang_item.code] = question_item['name_' + lang_item.code]
                                         )
                                     updateQuestionHandler(default_data)
                                     console.log("def:", default_data);
                                 }}
                            >
                                {activeLanguage.length && activeLanguage
                                    .filter(lang => lang.code === currentPageLanguage)
                                    .map((lang_item, index) =>
                                        <div key={lang_item.code} className="QuestionHeader d-flex">
                                            <span className="mr-2 text-info">Question:</span>
                                            <EditableSpan
                                                key={lang_item.code}
                                                item={question_item}
                                                updateHandler={updateQuestionHandler}
                                                fieldName={'name_'}
                                                langCode={lang_item.code}
                                                textValue={question_item['name_' + lang_item.code]}
                                            />
                                        </div>
                                    )}
                            </div>

                            <div className="CardBlockHederTab ml-auto">
                                {questionUpdateForm.some(questionUpdateForm => questionUpdateForm.id === question_item.id && questionUpdateForm.updated === 1) &&
                                <img src="/img/save.png" onClick={() => changeQuestion(question_item.id)}
                                     className="CardBlockHederImg mx-3"/>}
                                <img src="/img/Trash.png" onClick={() => {
                                    if (window.confirm('Delete this item?')) {
                                        deleteQuestion(question_item.id)
                                    }
                                }} className="CardBlockHederImg mx-1"/>
                            </div>
                        </div>

                        <div className="m-3 m-2">

                            <form className="FormBlock">

                                {activeLanguage && activeLanguage.length && activeLanguage
                                    .filter(lang => lang.code === currentPageLanguage)
                                    .map((lang, index) =>
                                        <div className="FormBlockElement" key={lang.code}>
                                            <input type="text" className="FormInput"
                                                   placeholder={"Enter answer text.. "}
                                                   name={"name_" + lang.code} onChange={changeAnswerHandler}
                                                   style={lang.code === "he" ? {
                                                       direction: "rtl",
                                                       textAlign: "right"
                                                   } : null}/>
                                            <button type="submit" className="ButtonBlock mt-0 ml-3" variant="success"
                                                    disabled={loading}
                                                    onClick={() => addAnswer(question_item.id)}>
                                                Add Answer
                                            </button>
                                        </div>
                                    )}


                            </form>

                            <table className="Table">
                                <thead>
                                <tr className="TableBlock">
                                    <th className="TableFirstLineBlock">#</th>
                                    <th className="TableFirstLineBlock">Options</th>
                                    <th className="TableFirstLineBlock">Actions</th>
                                </tr>
                                </thead>

                                <tbody>
                                {(question_item.answers && question_item.answers.length)
                                    ? question_item.answers.map((answer_item,index) =>
                                        <tr key={answer_item.id} >
                                            <td className="TableSecondLineBlock TableSecondLineText CenterContent d-flex">
                                                {index +1}
                                            </td>
                                            <td className="TableSecondLineBlock TableSecondLineInput TableSecondLineText"
                                                onClick={() => {
                                                let default_data = {id: answer_item.id}
                                                activeLanguage
                                                    .filter(lang => lang.code === currentPageLanguage)
                                                    .map((lang_item, index) =>
                                                        default_data['name_' + lang_item.code] = answer_item['name_' + lang_item.code]
                                                    )
                                                updateAnswerHandler(default_data)
                                            }}>

                                                {activeLanguage.length && activeLanguage
                                                    .filter(lang => lang.code === currentPageLanguage)
                                                    .map((lang_item, index) =>
                                                        <div key={lang_item.code} >
                                                            <EditableSpan
                                                                key={lang_item.code}
                                                                item={answer_item}
                                                                updateHandler={updateAnswerHandler}
                                                                fieldName={'name_'}
                                                                langCode={lang_item.code}
                                                                textValue={answer_item['name_' + lang_item.code]}
                                                            />

                                                        </div>
                                                    )}

                                            </td>

                                            <td className="TableSecondLineBlock  TableSecondLineBlockTrash">
                                                {answerUpdateForm.some(answerUpdateForm => answerUpdateForm.id === answer_item.id && answerUpdateForm.updated === 1) &&
                                                <img src="/img/save_white.png" onClick={() => changeAnswer(answer_item.id)}
                                                        className="TableSecondLineImg mx-3"/>}
                                                <img src="/img/TrashB.png" onClick={() => {
                                                    if (window.confirm('Delete this item?')) {
                                                        deleteAnswer(answer_item.id)
                                                    }
                                                }} className="TableSecondLineImg mx-1"/>
                                            </td>
                                        </tr>
                                    )

                                    : <tr><td><span className="NoElementsText">*It will be text answer until you add options</span></td></tr>
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                )


                : <div className="NoElementsText">No quizzes</div>
            }
        </>
    )
}

import {useState, useCallback, useEffect} from 'react'

const storageName = 'userData'

export const useAuth = () => {
  const [token, setToken] = useState(null)
  const [ready, setReady] = useState(false)
  const [tokenExp, setTokenExp] = useState(null)
  const [username, setUsername] = useState(null)
  const [role, setRole] = useState(null)
  const [group, setGroup] = useState(null)


  const login = useCallback((jwtToken, exp, username, role, group) => {
    setToken(jwtToken)
    setTokenExp(exp)
    setUsername(username)
    setRole(role)
    setGroup(group)

    localStorage.setItem(storageName, JSON.stringify({
      tokenExp: exp, token: jwtToken, login: username, role: role, group: group
    }))
  }, [])


  const logout = useCallback(() => {
    setToken(null)
    setTokenExp(null)
    setUsername(null)
    setRole(null)
    setGroup(null)
    localStorage.removeItem(storageName)
  }, [])


  useEffect(() => {
    const data = JSON.parse(localStorage.getItem(storageName))

    if (data && data.token) {
      const token_exp_format = Math.floor(Date.now() / 1000)
      console.log(Number.isInteger(data.tokenExp));
      if (Number.isInteger(data.tokenExp) && data.tokenExp < token_exp_format ) {
        //if token expired force logout
        // return logout()
        logout()
      }else{
        login(data.token, data.tokenExp, data.login, data.role, data.group)
      }

    }
    setReady(true)
  }, [login, logout])


  return { login, logout, role, username, group, token, tokenExp, ready}
}

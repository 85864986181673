import {useState, useCallback, useEffect} from 'react'

//const storageName = 'languagesData';

export const useLanguages = () => {

    const [pageLanguage, setPageLanguage] = useState(null);
    const [activeLanguage, setActiveLanguages] = useState([]);

    const defaultLanguage = 'en';
    const changePageLanguage = useCallback((lang) => {

        setPageLanguage(lang);
        localStorage.setItem('pageLanguage', JSON.stringify(lang));
    },[]);

    const initList = useCallback((list) => {

        setActiveLanguages(list);
        localStorage.setItem('languagesList', JSON.stringify(list));
        console.log("after init:",pageLanguage,activeLanguage);
    },[]);


    useEffect(() => {
        const list = JSON.parse(localStorage.getItem('languagesList'));
        const current = JSON.parse(localStorage.getItem('pageLanguage'));

        if (list)
            initList(list);

        if (current)
            changePageLanguage(current);
        else {
            console.log("empty local languageData storage");
            changePageLanguage(defaultLanguage);
        }

    },[initList,changePageLanguage]);


    return {pageLanguage, changePageLanguage, activeLanguage, initList}
};

import {useState, useCallback, useContext} from 'react'
import {AuthContext} from '../context/AuthContext'

export const useHttp = () => {
  const auth = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(false)
  const [error, setError] = useState(null)
  // const [cancelUpload, setCancelUpload] = useState(false)
  let cancelUpload = false


  const request = useCallback(async (url, method = 'GET', body = null, headers = {}, file = null) => {
    setLoading(true)
    try {

      if (body) {
        if ( headers['Content-Type'] === undefined ) {
					headers['Content-Type'] = 'application/json'
					body = JSON.stringify(body)
				}
				else if( headers['Content-Type'] === 'multipart/form-data' ) {
					delete headers['Content-Type']
				}
        // body = JSON.stringify(body)
        // headers['Content-Type'] = 'application/json'

        // headers['Content-Type'] ='application/x-www-form-urlencoded'; <-form_Data
      }

      //to get file progress we need xhr, fetch has no upload progress
      if (file !== true ) {
        const response = await fetch(process.env.REACT_APP_API+url, {method, body, headers})
        const data = await response.json()

        if ( response.status === 401 ) {
          auth.logout()
        }

        if ( response.status !== 200 && response.status !== 201 ) {
          console.log('error happened');

          setLoading(false)
          throw data
        }
        // setTimeout(() => {
        //   setLoading(false);
        // }, 3000);
        setLoading(false);

        return data
      }else{
        const xhr = new XMLHttpRequest();

        return new Promise(function (resolve, reject) {
          xhr.upload.onprogress = function(event) {
            // console.log(event.loaded + ' / ' + event.total);
            setProgress({total:event.total, progress:event.loaded})
            if (cancelUpload === true) {
              xhr.abort()
              cancelUpload = false
              throw reject({
                          message: 'Upload cancelled'
                      });
            }
          }
          xhr.onload = xhr.onerror = function() {
            if (this.status !== 200 && this.status !== 201 ) {

              throw reject({
                          message: xhr.response.message
                      });
            } else {
              resolve(xhr.response)
            }
          };
          xhr.responseType = 'json';
          xhr.open(method, process.env.REACT_APP_API+url, true);
           xhr.setRequestHeader('x-access-token', headers['x-access-token']);
          xhr.send(body);
        }).then(function(data) {
          setProgress(false)
          setLoading(false);
          return data;

        }).catch(function(e) {
          setProgress(false)
          setLoading(false)
          throw e;
        });
      }

    } catch (e) {
      setError(e.message)
      throw e
    }
  }, [auth])

  const clearError = useCallback(() => setError(null), [])
  const abortUpload = useCallback( () =>{
    cancelUpload=true
    // setCancelUpload(true)
  }, [] )

  return { loading, request, error, clearError, progress, abortUpload }
}

import React from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'

import {AuthPage} from './pages/AuthPage'

import {ContentPage} from './pages/admin/ContentPage'
import {SettingsPage} from './pages/admin/SettingsPage'
import {StatisticPage} from './pages/admin/StatisticPage'

import {GroupsPage} from './pages/admin/GroupsPage'
import {GroupDetailPage} from './pages/admin/GroupDetailPage'

import {QuizDetailPage} from './pages/admin/QuizDetailPage'

export const useRoutes = (isAuthenticated, role) => {
  if (isAuthenticated && role === 'admin') {

    return (
      <Switch>
        <Route path="/content" exact>
          <ContentPage />
        </Route>
        <Route path="/statistic" exact>
          <StatisticPage />
        </Route>
        <Route path="/client" exact>
          <GroupsPage />
        </Route>
        <Route path="/client/:id" exact>
          <GroupDetailPage />
        </Route>
        <Route path="/quiz/:id" exact>
          <QuizDetailPage />
        </Route>
        <Route path="/settings" exact>
          <SettingsPage />
        </Route>
        <Redirect to="/content" />
      </Switch>
    )
  }else if (isAuthenticated && role === 'moderator') {
    return (
      <Switch>
        <Route path="/statistic" exact>
          <StatisticPage />
        </Route>
        <Route path="/clients" exact>
          <GroupDetailPage />
        </Route>
        <Route path="/quiz/:id" exact>
          <QuizDetailPage />
        </Route>

        <Redirect to="/clients" />
      </Switch>
    )
  }else if (isAuthenticated && role === 'analytic') {
    return (
      <Switch>
        <Route path="/statistic" exact>
          <StatisticPage />
        </Route>

        <Redirect to="/statistic" />
      </Switch>
    )
  }

  return (
    <Switch>
      <Route path="/" exact>
        <AuthPage />
      </Route>
      <Redirect to="/" />
    </Switch>
  )
}
